.header_gnav_bottom_list_item_link,
.header_gnav_top_list_item_link,
.breadcrumb_list_item_link,
.footer_upper_list_item_link,
.footer_nav_list_item_parent_link,
.footer_nav_list_item_parent a,
.footer_nav_list_item_child a {
  @include textGradient($c_text, $c_pink, '> span', $p_slide);

  .studio > & {
    @include textGradient($c_text, $c_studio, '> span', $p_slide);
  }

  .academy > & {
    @include textGradient($c_text, $c_academy, '> span', $p_slide);
  }
}

.breadcrumb_list_item_link {
  @include textGradient($c_pink, $c_text, '> span', $p_slide);
}

.footer_bottom_list_item a {
  @include textGradient($c_text, $c_pink, false, $p_slide);
}

.course_list_item_figure,
.course_list_item_bnr_link,
.footer_pager_list_item_figure,
.top_concept_list_item_figure,
.top_course_list_item_figure {
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.course_list_item_link {
  transition: box-shadow $t_fade, background $t_fade;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    background: linear-gradient(315deg, $c_gray_light, $c_gray_light);
    transition: opacity $t_fade;
    @include responsive((
      border-radius: 96,
    ));
    @include mq(sp) {
      border-radius: mycalcSP(32);
    }
  }

  &:hover {
    box-shadow:  -4.5px -4.5px 11px #e1e0e1, 4.5px 4.5px 11px $c_white;
    @include mq(pc) {
      box-shadow: mycalc(-9 -9 22) #e1e0e1, mycalc(9 9 22) $c_white;
    }

    &:before {
      opacity: 1;
    }
  }
}





@keyframes Humberger_bar_z {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    top: 0;
    margin-top: 0;
    left: 0;
    margin-left: 0;
    border-radius: mycalcSP(20);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: 0;
    margin-top: 0;
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
  }
  40% {
    top: 0;
  }
  60% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: 30%;
    margin-top: 0;
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(-45deg);
  }
  80% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    top: 50%;
    left: 0;
    margin-top: mycalcSP(-2);
    border-radius: mycalcSP(20);
    transform: rotate(-35deg);
  }
  90% {
    transform: rotate(-50deg);
  }
  100% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    top: 50%;
    left: 0;
    margin-top: mycalcSP(-2);
    border-radius: mycalcSP(20);
    transform: rotate(-45deg);
  }
}

@keyframes Humberger_bar_x {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    top: 50%;
    margin-top: mycalcSP(-2);
    left: 0;
    border-radius: mycalcSP(20);
  }
  40% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: 50%;
    margin-top: mycalcSP(-2);
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
  }
  70% {
    width: mycalcSP(20);
    height: mycalcSP(20);
    top: 50%;
    margin-top: mycalcSP(-10);
    left: 50%;
    margin-left: mycalcSP(-10);
    border-radius: 50%;
  }
  100%{
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    border-radius: 50%;
  }
}

@keyframes Humberger_bar_y {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    bottom: 0;
    margin-bottom: 0;
    right: 0;
    margin-left: 0;
    border-radius: mycalcSP(20);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    bottom: 0;
    margin-bottom: 0;
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
  }
  40% {
    bottom: 0;
  }
  60% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    bottom: 30%;
    margin-bottom: 0;
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(45deg);
  }
  80% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    bottom: 50%;
    margin-bottom: mycalcSP(-2);
    right: 0;
    border-radius: mycalcSP(20);
    transform: rotate(55deg);
  }
  90% {
    transform: rotate(40deg);
  }
  100% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    bottom: 50%;
    margin-bottom: mycalcSP(-2);
    right: 0;
    border-radius: mycalcSP(20);
    transform: rotate(45deg);
  }
}

@keyframes Humberger_bar_z_rev {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    top: 50%;
    left: 0;
    margin-top: mycalcSP(-2);
    border-radius: mycalcSP(20);
    transform: rotate(-45deg);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: 30%;
    margin-top: 0;
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
  }
  45% {
    top: 0;
  }
  55% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: 0;
    margin-top: 0;
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(10deg);
  }
  80% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    top: 0;
    margin-top: 0;
    left: 0;
    margin-left: 0;
    border-radius: mycalcSP(20);
    transform: rotate(-3deg);
  }
  100% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    top: 0;
    margin-top: 0;
    left: 0;
    margin-left: 0;
    border-radius: mycalcSP(20);
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bar_x_rev {
  0% {
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    border-radius: 50%;
  }
  30% {
    width: mycalcSP(20);
    height: mycalcSP(20);
    top: 50%;
    margin-top: mycalcSP(-10);
    left: 50%;
    margin-left: mycalcSP(-10);
    border-radius: 50%;
  }
  60%{
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: 50%;
    margin-top: mycalcSP(-2);
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
  }
  100%{
    width: mycalcSP(40);
    height: mycalcSP(4);
    top: 50%;
    margin-top: mycalcSP(-2);
    left: 0;
    border-radius: mycalcSP(20);
  }
}

@keyframes Humberger_bar_y_rev {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    bottom: 50%;
    margin-bottom: mycalcSP(-2);
    right: 0;
    border-radius: mycalcSP(20);
    transform: rotate(45deg);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    bottom: 30%;
    margin-bottom: 0;
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
  }
  45% {
    bottom: 0;
  }
  55% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    bottom: 0;
    margin-bottom: 0;
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(-10deg);
  }
  80% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    bottom: 0;
    margin-bottom: 0;
    right: 0;
    margin-left: 0;
    border-radius: mycalcSP(20);
    transform: rotate(3deg);
  }
  100% {
    width: mycalcSP(40);
    height: mycalcSP(4);
    bottom: 0;
    margin-bottom: 0;
    right: 0;
    margin-left: 0;
    border-radius: mycalcSP(20);
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bg {
  0% {
    border-radius: 0 0 0 mycalcSP(72);
    transform: translate(calc(100% - #{mycalcSP(144)}), calc(-100% + #{mycalcSP(144)}));
  }
  10% {
    border-radius: 0 0 mycalcSP(72) 50%;
  }
  80% {
    border-radius: 0 0 mycalcSP(72) 50%;
  }
  100% {
    border-radius: 0;
    transform: translate(0, 0);
  }
}

@keyframes Humberger_bg_rev {
  0% {
    border-radius: 0;
    transform: translate(0, 0);
  }
  20% {
    border-radius: 0 0 mycalcSP(72) 50%;
  }
  40% {
    border-radius: 0 0 mycalcSP(72) 50%;
  }
  100% {
    border-radius: 0 0 0 mycalcSP(72);
    transform: translate(calc(100% - #{mycalcSP(144)}), calc(-100% + #{mycalcSP(144)}));
  }
}

@keyframes TEXT-MASK {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes TEXT-MASK-OVER {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-BACK {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes TEXT-MASK-OVER-BACK {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-OVER-SLUSH {
  0% {
    background-position: 100% 0;
  }

  30% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-OVER-LOOP {
  0% {
    background-position: 100% 0;
  }

  40% {
    background-position: -100% 0;
  }

  100% {
    background-position: 0 0;
  }
}


/* 円の回転アニメーション */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}