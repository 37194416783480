/*
 * footer.scss
 *
 * フッター用コンポーネント
 */

.footer {
  width: 100%;
  color: $c_text;
  position: relative;
  @include responsive((
    padding-top: 112,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(96);
  }


  &_pagetop {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $c_white;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    border: solid $c_white;
    overflow: hidden;
    @include responsive((
      width: 128,
      height: 128,
      border-width: 4,
    ));
    @include mq(sp) {
      width: mycalcSP(128);
      height: mycalcSP(128);
      margin-top: mycalcSP(32);
      border: 0;
    }

    .ico_push_up {
      position: relative;
      z-index: 1;
      @include responsive((
        width: 32,
        height: 32,
      ));
      @include mq(sp) {
        width: mycalcSP(32);
        height: mycalcSP(32);
      }
    }

    &:before {
      z-index: 0;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: $c_pink_light;
      transition: height $t_slide, left $t_slide;
      border-radius: 50%;
    }

    &:hover {
      &:before {
        height: 100%;
        top: auto;
        bottom: 0;
      }
    }
  }

  &_contents {
    box-sizing: border-box;
    margin: 0 auto;
    @include responsive((
      width: 2400,
      padding: 96 0 320,
    ));
    @include mq(sp) {
      width: 100%;
      padding: mycalcSP(0 40 80);
    }
  }

  &_upper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    @include responsive((
      margin-bottom: 80,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(80);
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
    }

    &_list {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $c_white;
      @include responsive((
        margin-right: 32,
        margin-left: 160,
        padding: 40 128,
        border-radius: 64,
      ));
      @include mq(sp) {
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        padding: 0;
        border-radius: 0;
        background: none;
      }

      &_item {
        &:not(:first-child) {
          @include responsive((
            margin-left: 48,
          ));
          @include mq(sp) {
            margin: mycalcSP(32 auto 0);
          }
        }

        &_link {
          letter-spacing: 0;
          font-weight: bold;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          @include responsive((
            font-size: 32,
            line-height: 48,
          ));
          @include mq(sp) {
            box-sizing: border-box;
            background: $c_white;
            justify-content: center;
            font-size: mycalcSP(28);
            line-height: mycalcSP(48);
            padding: mycalcSP(40 48);
            min-width: mycalcSP(396);
            border-radius: mycalcSP(64);
          }

          &_txt {
            font-weight: bold;
          }

          svg {
            @include responsive((
              width: 32,
              height: 32,
              margin-right: 16,
            ));
            @include mq(sp) {
              width: mycalcSP(32);
              height: mycalcSP(32);
              margin-right: mycalcSP(16);
            }
          }
        }
      }
    }
  }

  &_top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    @include responsive((
      margin-bottom: 108,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(128);
    }
  }

  &_nav {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &_list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include mq(sp) {
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &_item {
        @include responsive((
          margin-right: 60,
        ));
        @include mq(tb) {
          margin-right: mycalcWide(60);
        }
        @include mq(sp) {
          width: 100%;
          margin: 0;
          border-bottom: mycalcSP(2) solid $c_gray;
        }

        &:last-child {
          margin-right: 0;

          @include mq(sp) {
            margin-bottom: 0;
          }
        }

        &_parent {
          a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;
            @include responsive((
              font-size: 28,
              line-height: 48,
              margin-bottom: 20,
            ));
            @include mq(sp) {
              font-size: mycalcSP(26);
              line-height: mycalcSP(36);
              margin-bottom: 0;
            }
          }

          &_link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @include responsive((
              margin-bottom: 36,
            ));
            @include mq(sp) {
              width: 100%;
              margin-bottom: 0;
              justify-content: space-between;
            }

            .ico_arw {
              @include responsive((
                margin-left: 32,
              ));
              @include mq(sp) {
                margin-left: 0;
              }
            }
          }

          .ico_arw {
            @include responsive((
              margin-left: 16,
            ));
            @include mq(sp) {
              margin-left: 0;
            }
          }
        }

        &_child {
          @include responsive((
            font-size: 24,
            line-height: 40,
            margin-bottom: 24,
            padding-left: 8,
          ));
          @include mq(sp) {
            display: none;
            font-size: mycalcSP(24);
            line-height: mycalcSP(40);
            margin-bottom: mycalcSP(24);
            padding-left: mycalcSP(16);
          }
        }

        &_txt {
          font-weight: inherit;
          letter-spacing: 0;

          &.parent {
            font-weight: bold;
            display: block;
            @include responsive((
              font-size: 32,
              line-height: 48,
            ));
            @include mq(sp) {
              font-size: mycalcSP(28);
              line-height: mycalcSP(40);
              margin-bottom: 0;
              justify-content: space-between;
              padding: mycalcSP(48 0);
            }
          }
        }
      }
    }

    &_children {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      @include responsive((
        margin-top: 32,
      ));
      @include mq(sp) {
        margin-top: 0;
        flex-wrap: wrap;
      }

      &_list {
        @include responsive((
          margin-right: 52,
        ));
        @include mq(tb) {
          margin-right: mycalcWide(52);
        }
        @include mq(sp) {
          width: 100%;
          margin-right: 0;
          margin-top: mycalcSP(-24);
          padding-bottom: mycalcSP(24);

          .footer_nav_list_item_parent a {
            width: 100%;
            box-sizing: border-box;
            justify-content: space-between;
            padding: mycalcSP(24 0 24 32);
          }
        }

        &.studio {
          .ico_arw path {
            fill: $c_studio;
          }
        }

        &.academy {
          .ico_arw path {
            fill: $c_academy;
          }
        }
      }
    }

    .ico_arw {
      @include responsive((
        width: 32,
        height: 32,
      ));
      @include mq(sp) {
        width: mycalcSP(32);
        height: mycalcSP(32);
        margin-right: mycalcSP(8);
      }
    }
  }

  &_middle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    @include responsive((
      margin-bottom: 80,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(80);
    }
  }

  &_contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    @include mq(sp) {
      width: 100%;
      margin-top: mycalcSP(48);
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
    }

    .tel {
      @include mq(sp) {
        width: mycalcSP(640);
      }

      &_number {
        @include mq(sp) {
          border-color: $c_pink;
        }
      }

      &_schedule {
        @include mq(sp) {
          text-align: center;
        }
      }
    }

    .mail {
      background: $c_white;
      border-color: $c_pink;

      .mail_link {
        @include textGradient($c_pink, $c_white, '.btn_link_txt', $p_slide, .1s);

        &:hover {
          .ico_mail {
            path {
              fill: $c_white;
            }
          }
        }

        &:after {
          background: $c_pink;
        }
      }

      .mail_link_txt_way {
        @include minq(sp) {
          display: none;
        }
      }

      .ico_arw {
        display: none;
      }
    }

    .btn_circle {
      margin-right: 0;

      @include mq(sp) {
        margin-left: 0;
        margin-top: mycalcSP(48);
      }
    }
  }

  &_logo {
    @include responsive((
      width: 600,
    ));
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      padding: mycalcSP(0 10);
    }

    &_link {
      text-align: center;
      display: block;
      @include responsive((
        width: 418,
      ));
      @include mq(sp) {
        width: mycalcSP(336);
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &_address {
    letter-spacing: 0;
    text-align: left;
    @include responsive((
      font-size: 24,
      line-height: 40,
      margin-top: 48,
    ));
    @include mq(sp) {
      font-size: mycalcSP(24);
      line-height: mycalcSP(40);
      margin-top: mycalcSP(32);
    }
  }

  &_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include mq(sp) {
      align-items: flex-start;
      flex-flow: column;
    }

    &_sns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include mq(sp) {
        width: 100%;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: mycalcSP(0 10);
      }

      &_ttl {
        font-weight: bold;
        letter-spacing: 0;
        @include responsive((
          font-size: 28,
          line-height: 48,
          margin-bottom: 16,
        ));
        @include mq(sp) {
          font-size: mycalcSP(28);
          line-height: mycalcSP(48);
          margin-bottom: mycalcSP(16);
          padding-left: mycalcSP(10);
        }
      }

      &_box {
        @include responsive((
          margin-right: 80
        ));
        @include mq(sp) {
          width: 100%;
          margin-right: 0;
          margin-bottom: mycalcSP(80);
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include mq(sp) {
          align-items: flex-start;
        }

        &_item {
          @include responsive((
            margin-right: 32,
          ));
          @include mq(sp) {
            margin-right: mycalcSP(32);
          }

          &:last-child {
            margin-right: 0;
          }

          &_link {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            @include responsive((
              width: 80,
              height: 80,
            ));
            @include mq(sp) {
              width: mycalcSP(80);
              height: mycalcSP(80);
            }

            svg, img {
              display: block;
              @include responsive((
                width: 80,
                height: 80,
              ));
              @include mq(sp) {
                width: mycalcSP(80);
                height: mycalcSP(80);
              }
            }

            .circle {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              border-radius: 50%;
              transition: transform $t_scroll;
              transform: scale(.5);

              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                box-sizing: border-box;
                border: 1px dashed transparent;
                transition: border-color $t_scroll;
              }
            }

            &:hover {
              .circle {
                transform: scale(1.1);

                &:after {
                  border-color: $c_pink;
                  animation: rotation 4s infinite linear;
                }
              }
            }

          }
        }
      }
    }

    &_list {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      @include mq(sp) {
        width: 100%;
        flex-flow: column;
        justify-content: flex-end;
        align-items: flex-end;
      }

      &_item {
        letter-spacing: 0;
        @include responsive((
          font-size: 24,
          line-height: 48,
          margin-right: 64,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(48);
          margin-right: 0;
          margin-bottom: mycalcSP(24);
        }

        a {
          color: $c_text;
          border-bottom: 0;

          .academy &,
          .studio & {
            color: $c_text;
          }
        }
      }
    }

    .copyright {
      font-family: $f_en_sans;

      @include responsive((
        font-size: 24,
        line-height: 48,
      ));
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(24);
        text-align: right;
        margin-top: mycalcSP(60);
      }
    }
  }

  &_offer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;
    @include responsive((
      margin-bottom: 112,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(96);
    }

    &_sec {
      text-align: center;
      box-sizing: border-box;
      @include responsiveFull((
        width: 1896,
        padding: 192 80,
      ));
      @include mq(wide) {
        width: mycalcFull(1896);
      }
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(80 40);
      }

      &_desc {
        font-weight: bold;
        @include responsive((
          font-size: 32,
          line-height: 48,
          margin-bottom: 80
        ));
        @include mq(sp) {
          letter-spacing: 0;
          font-size: mycalcSP(28);
          line-height: mycalcSP(48);
          margin-bottom: mycalcSP(48);
        }
      }

      .btn {
        @include responsive((
          width: 720,
        ));
        @include mq(sp) {
          width: 100%;
        }
      }
    }

    &_lesson {
      background: $c_pink;
      color: $c_white;
      @include responsive((
        border-radius: 0 160 160 0,
      ));
      @include mq(sp) {
        border-radius: mycalcSP(96);
        margin-bottom: mycalcSP(52);

        .footer_offer_sec_desc {
          text-align: left;
        }
      }

      .studio & {
        background: $c_studio;
      }

      .academy & {
        background: $c_academy;
      }

      .btn {
        border-color: $c_white;
        background: $c_white;
        margin: 0 auto;
        @include mq(sp) {
          width: mycalcSP(640);
          height: mycalcSP(112);
        }

        &_link {
          color: $c_pink;
          @include textGradient($c_pink, $c_white, '.btn_link_txt', $p_slide, .1s);

          .studio & {
            color: $c_studio;
            @include textGradient($c_studio, $c_white, '.btn_link_txt', $p_slide, .1s);
          }

          .academy & {
            color: $c_academy;
            @include textGradient($c_academy, $c_white, '.btn_link_txt', $p_slide, .1s);
          }

          .ico_arw path {
            fill: $c_pink;

            .studio & {
              fill: $c_studio;
            }

            .academy & {
              fill: $c_academy;
            }
          }

          &:hover {
            .ico_arw path {
              fill: $c_white;
            }
          }

          &:after {
            background: $c_pink;

            .studio & {
              background: $c_studio;
            }

            .academy & {
              background: $c_academy;
            }
          }
        }
      }
    }

    &_contact {
      background: $c_white;
      color: $c_pink;
      @include responsive((
        border-radius: 160 0 0 160,
      ));
      @include mq(sp) {
        border-radius: mycalcSP(96);
      }

      .studio & {
        color: $c_studio;
      }

      .academy & {
        color: $c_academy;
      }

      .tel {
        @include responsive((
          margin-bottom: 64
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(32);
        }

        &_number {
          @include mq(sp) {
            width: mycalcSP(640);
            height: mycalcSP(112);
            margin-left: auto;
            margin-right: auto;
          }

          &_txt {
            .studio & {
              color: $c_studio;
            }

            .academy & {
              color: $c_academy;
            }
          }
        }

        &_schedule {
          @include mq(sp) {
            font-size: mycalcSP(24);
            line-height: mycalcSP(40);
          }
        }
      }

      .mail {
        @include responsive((
          height: 160,
        ));
        @include mq(sp) {
          width: mycalcSP(640);
          height: mycalcSP(112);
          margin-left: auto;
          margin-right: auto;
        }
      }

      .tel_number_ico path,
      .ico_mail path {
        .studio & {
          fill: $c_studio;
        }

        .academy & {
          fill: $c_academy;
        }
      }

      .btn {
        border-color: $c_pink;
        background: $c_white;
        margin: 0 auto;

        .studio & {
          border-color: $c_studio;
        }

        .academy & {
          border-color: $c_academy;
        }

        &_link {
          color: $c_pink;
          @include textGradient($c_pink, $c_white, '.btn_link_txt', $p_slide, .1s);

          .studio & {
            color: $c_studio;
            @include textGradient($c_studio, $c_white, '.btn_link_txt', $p_slide, .1s);
          }

          .academy & {
            color: $c_academy;
            @include textGradient($c_academy, $c_white, '.btn_link_txt', $p_slide, .1s);
          }

          .ico_mail {
            @include responsive((
              margin-right: 8
            ));
          }

          .ico_arw path {
            fill: $c_pink;

            .studio & {
              fill: $c_studio;
            }

            .academy & {
              fill: $c_academy;
            }
          }

          &:hover {
            .ico_mail path,
            .ico_arw path {
              fill: $c_white;
            }
          }
          &:after {
            background: $c_pink;

            .studio & {
              background: $c_studio;
            }

            .academy & {
              background: $c_academy;
            }
          }
        }
      }

      &.middle_offer_sec {
        @include responsive((
          margin: 120 auto 240,
          border-radius: 160
        ));
        @include mq(sp) {
          border-radius: mycalcSP(96);
        }
      }

    }
  }

  &_pager {
    @include responsive((
      margin-bottom: 240,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(128);
    }

    .ttl_sec {
      @include responsive((
        margin-bottom: 128,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(128);
      }

      .home & {
        @include responsive((
          margin-bottom: 96,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(96);
        }
      }

      &_en {
        @include responsive((
          font-size: 192,
          line-height: 208,
          margin-bottom: 16,
        ));
        @include mq(sp) {
          font-size: mycalcSP(96);
          line-height: mycalcSP(96);
          margin-bottom: 0;
        }

        .home & {
          @include responsive((
            font-size: 320,
            line-height: 320,
            margin-bottom: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(112);
            line-height: mycalcSP(112);
            margin-bottom: mycalcSP(32);
            padding-left: 0;
          }
        }
      }

      &_ja {
        letter-spacing: .1em;
        @include responsive((
          font-size: 48,
          line-height: 64,
          padding-left: 0,
        ));
        @include mq(sp) {
          font-size: mycalcSP(44);
          line-height: mycalcSP(72);
          padding-left: 0;
        }

        .home & {
          letter-spacing: 0;
          @include responsive((
            font-size: 56,
            line-height: 96,
            padding-left: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(44);
            line-height: mycalcSP(72);
            padding-left: 0;
          }
        }

      }
    }

    &_list {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      &_item {
        @include responsive((
          width: 1112,
          margin: 0 56,
        ));
        @include mq(sp) {
          width: mycalcSP(670);
          margin: 0;
        }

        &:last-child {
          @include mq(sp) {
            margin-bottom: 0;
          }
        }

        &_figure {
          position: relative;
          overflow: hidden;
          box-sizing: border-box;
          @include responsive((
            width: 1112,
            height: 624,
            border-radius: 96,
            padding: 16,
          ));
          @include mq(sp) {
            width: mycalcSP(670);
            height: mycalcSP(444);
            border-radius: mycalcSP(96);
            padding: mycalcSP(16);
          }

          &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            border: $c_white solid;
            @include responsive((
              border-radius: 96,
              border-width: 32,
            ));
            @include mq(sp) {
              border-radius: mycalcSP(96);
              border-width: mycalcSP(32);
            }
          }

          &_img {
            @include responsive((
              width: 1080,
              height: 592,
              border-radius: 96,
            ));
            @include mq(sp) {
              width: mycalcSP(638);
              height: mycalcSP(412);
              border-radius: mycalcSP(96);
            }
          }
        }
      }
    }

    .btn {
      margin: 0 auto;
      transform: translateY(-40px);
      @include mq(pc) {
        transform: translateY(#{mycalc(-80)});
      }
      @include mq(sp) {
        width: mycalcSP(560);
        height: mycalcSP(112);
        transform: translateY(#{mycalcSP(-64)});
      }
    }
  }
}