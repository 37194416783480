.course {

  &_music {
    position: relative;
    z-index: 0;
    @include responsive((
      margin-bottom: 256,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(192);
    }
  }

  &_english {
    @include responsive((
      margin-bottom: 256,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(192);
    }

    .ttl_sec {
      position: relative;
      @include responsive((
        margin-bottom: 64,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(64);
      }

      &_en {
        @include responsive((
          margin-bottom: 128,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(128);
        }
      }

      &_ja {
        letter-spacing: .1em;
        position: relative;
        @include responsive((
          padding-left: 276,
        ));
        @include mq(sp) {
          letter-spacing: 0;
          padding-top: mycalcSP(40);
          padding-left: 0;
          margin-left: mycalcSP(32);
        }

        .speech_bubble {
          position: absolute;
          letter-spacing: .1em;
          font-weight: bold;
          transform: rotate(-10.21deg);
          color: $c_pink;
          @include responsive((
            font-size: 32,
            line-height: 48,
            top: -56,
            left: 50,
          ));
          @include mq(sp) {
            font-size: mycalcSP(24);
            line-height: mycalcSP(32);
            top: mycalcSP(-50);
            left: mycalcSP(-6);
          }

          .studio & {
            color: $c_studio;
          }

          .academy & {
            color: $c_academy;
          }
        }
      }

      .svg_speech_bubble {
        position: absolute;
        left: 0;
        @include responsive((
          width: 320,
          height: 91.22,
          bottom: 52,
        ));
        @include mq(sp) {
          width: mycalcSP(224);
          height: mycalcSP(72);
          bottom: mycalcSP(88);
        }

        path {
          stroke: $c_pink;

          .studio & {
            stroke: $c_studio;
          }

          .academy & {
            stroke: $c_academy;
          }
        }
      }
    }


    &_desc {
      letter-spacing: .1em;
      @include responsive((
        font-size: 32,
        line-height: 80,
        margin-bottom: 80,
        padding: 0 32,
      ));
      @include mq(sp) {
        letter-spacing: 0;
        font-size: mycalcSP(30);
        line-height: mycalcSP(64);
        margin-bottom: mycalcSP(96);
        padding: mycalcSP(0 32);
      }
    }
  }

  &_category {
    @include responsive((
      margin-bottom: 64,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(64);
    }

    .ttl_ico {
      @include responsive((
        margin-bottom: 32,
        padding-left: 36,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(32);
        padding-left: 0;
      }
    }

    &_desc {
      letter-spacing: .1em;
      @include responsive((
        font-size: 32,
        line-height: 72,
        margin-bottom: 64,
        padding-left: 28,
      ));
      @include mq(sp) {
        font-size: mycalcSP(28);
        line-height: mycalcSP(48);
        margin-bottom: mycalcSP(64);
        padding-left: 0;
      }
    }

    &.course_english {
      @include responsive((
        padding-top: 16,
      ));
      @include mq(sp) {
        padding-top: mycalcSP(16);
      }
    }
  }

  &_list {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    @include responsive((
      padding: 0 32,
    ));
    @include mq(sp) {
      padding: 0;
    }

    &_item {
      display: flex;
      justify-content: center;
      align-items: stretch;
      @include responsive((
        width: 1112,
        min-height: 464,
        border-radius: 96,
        margin-bottom: 64,
      ));
      @include mq(sp) {
        width: 100%;
        min-height: auto;
        border-radius: mycalcSP(32);
        margin-bottom: mycalcSP(32);
      }

      &.long {
        @include responsive((
          width: 2336,
        ));
        @include mq(sp) {
          width: 100%;
        }
      }

      &_link {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        background: $c_white;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        z-index: 0;
        @include responsive((
          border-radius: 96,
          padding: 48,
        ));
        @include mq(sp) {
          border-radius: mycalcSP(32);
          padding: mycalcSP(32);
          justify-content: flex-end;
        }

        .course_english & {
          background: $c_gray;
        }
      }
      
      &_data {
        @include responsive((
          width: 520,
          margin-right: 32,
        ));
        @include mq(sp) {
          width: 100%;
          margin-right: 0;
        }

        .long & {
          @include responsive((
            width: 1700,
            margin-right: 76,
          ));
          @include mq(sp) {
            width: 100%;
            margin-right: 0;
          }
        }
      }

      &_ttl {
        letter-spacing: 0;
        font-weight: bold;
        font-family: $f_point;
        @include responsive((
          font-size: 40,
          line-height: 48,
          margin-top: 16,
          margin-bottom: 32,
        ));
        @include mq(sp) {
          width: 100%;
          height: mycalcSP(192);
          box-sizing: border-box;
          font-size: mycalcSP(32);
          line-height: mycalcSP(48);
          margin-top: 0;
          padding-left: mycalcSP(224);
          padding-right: mycalcSP(32);
          margin-bottom: mycalcSP(32);
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        span {
          font-weight: inherit;
          display: inline-block;
        }

        small {
          font-weight: inherit;
          @include responsive((
            font-size: 32,
            line-height: 40,
          ));
          @include mq(sp) {
            font-size: mycalcSP(28);
            line-height: mycalcSP(36);
          }
        }

        .course_english & {
          @include responsive((
            line-height: 64,
          ));
          @include mq(sp) {
            line-height: mycalcSP(48);
          }
        }
      }

      &_desc {
        letter-spacing: 0;
        z-index: 0;
        @include responsive((
          font-size: 28,
          line-height: 56,
        ));
        @include mq(sp) {
          font-size: mycalcSP(28);
          line-height: mycalcSP(48);
        }

        small {
          font-weight: bold;
          display: inline-block;
          @include responsive((
            font-size: 24,
            line-height: 40,
            margin-top: 12,
          ));
          @include mq(sp) {
            font-size: mycalcSP(24);
            line-height: mycalcSP(40);
            margin-top: mycalcSP(12);
          }
        }
      }

      &_figure {
        order: -1;
        overflow: hidden;
        z-index: 0;
        @include responsive((
          width: 368,
          height: 368,
          border-radius: 80,
          margin-right: 48,
        ));
        @include mq(sp) {
          position: absolute;
          width: mycalcSP(192);
          height: mycalcSP(192);
          border-radius: mycalcSP(32);
          margin-right: 0;
          margin-bottom: 0;
          top: mycalcSP(32);
          left: mycalcSP(32);
        }

        &_img {
          display: block;
          width: 100%;
          height: auto;
        }

      }

      .ico_arw {
        align-self: flex-end;
        @include responsive((
          width: 48,
          height: 48,
        ));
        @include mq(sp) {
          width: mycalcSP(48);
          height: mycalcSP(48);
          margin-top: mycalcSP(16);
          justify-self: flex-end;
        }

        path {
          .studio & {
            fill: $c_studio;
          }

          .academy & {
            fill: $c_academy;
          }
        }
      }

      &_mark {
        position: absolute;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c_white;
        background: $c_pink;
        font-weight: bold;
        @include responsive((
          width: 160,
          height: 160,
          top: 32,
          left: 32,
          font-size: 28,
          line-height: 40,
        ));
        @include mq(sp) {
          width: mycalcSP(120);
          height: mycalcSP(120);
          top: mycalcSP(-12);
          left: mycalcSP(-12);
          font-size: mycalcSP(22);
          line-height: mycalcSP(32);
        }

        .studio & {
          background: $c_studio;
        }
        .academy & {
          background: $c_academy;
        }
      }


      &_bnr {

        &_data {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: column;

          @include mq(sp) {
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        &_ttl {
          color: $c_white;
          border-bottom: 1px solid $c_white;
          font-weight: bold;
          letter-spacing: 0;
          font-family: $f_point;
          @include responsive((
            font-size: 48,
            line-height: 48,
            padding-bottom: 16,
            margin-bottom: 16,
          ));
          @include mq(sp) {
            font-size: mycalcSP(32);
            line-height: mycalcSP(48);
            padding-bottom: mycalcSP(16);
            margin-bottom: mycalcSP(16);
          }
        }

        &_desc {
          color: $c_white;
          font-weight: bold;
          letter-spacing: 0;
          font-family: $f_point;
          @include responsive((
            font-size: 28,
            line-height: 56,
          ));
          @include mq(sp) {
            font-size: mycalcSP(28);
            line-height: mycalcSP(48);
          }
        }

        &_figure {
          position: absolute;
          z-index: 0;
          overflow: hidden;
          @include responsive((
            border-radius: 80,
          ));
          @include mq(sp) {
            position: relative;
            border-radius: mycalcSP(32);
          }
        }

        .ico_arw {
          position: absolute;
          @include responsive((
            bottom: 100,
            right: 100,
          ));
          @include mq(sp) {
            bottom: mycalcSP(50);
            right: mycalcSP(50);
          }

          path,
          .studio & path,
          .academy & path{
            fill: $c_white;
          }
        }
      }

    }
  }

  &_scene {
    @include responsive((
      margin-bottom: 192,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(192);
    }

    &_list {
      &_item,
      .slick-slide {
        overflow: hidden;
        @include responsive((
          width: 640,
          height: 640,
          border-radius: 96,
          margin-right: 48,
        ));
        @include mq(sp) {
          width: mycalcSP(336);
          height: mycalcSP(336);
          border-radius: mycalcSP(64);
          margin-right: mycalcSP(48);
        }
        &_img {
          display: block;
          @include responsive((
            width: 640,
            border-radius: 96,
          ));
          @include mq(sp) {
            width: mycalcSP(336);
            border-radius: mycalcSP(64);
          }
        }
      }
    }
  }

  &_desc {
    @include responsive((
      margin-bottom: 160,
    ));
    .contents_post {
      @include responsive((
        padding: 0 32,
      ));
    }
  }
}

.plan {
  position: relative;
  z-index: 0;

  &_list {
    width: 100%;
    box-sizing: border-box;
    @include responsive((
      padding: 0 32,
      margin-bottom: 192,
    ));
    @include mq(sp) {
      padding: 0;
      margin-bottom: mycalcSP(96);
    }

    &_item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      background: $c_white;
      width: 100%;
      box-sizing: border-box;
      @include responsive((
        padding: 64 48 64 128,
        border-radius: 96,
        margin-bottom: 32,
      ));
      @include mq(sp) {
        padding: mycalcSP(40);
        border-radius: mycalcSP(32);
        margin-bottom: mycalcSP(32);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &_ttl {
        letter-spacing: 0;
        font-weight: bold;
        @include responsive((
          width: 520,
          font-size: 44,
          line-height: 48,
          margin-right: 64,
        ));
        @include mq(sp) {
          letter-spacing: .1em;
          width: 100%;
          font-size: mycalcSP(32);
          line-height: mycalcSP(48);
          margin-right: 0;
          margin-bottom: mycalcSP(32);
        }

        small {
          font-weight: bold;
          letter-spacing: .1em;
          display: block;
          color: $c_pink;

          @include responsive((
            font-size: 32,
            line-height: 48,
            margin-bottom: 16,
          ));
          @include mq(sp) {
            font-size: mycalcSP(32);
            line-height: mycalcSP(48);
            margin-bottom: mycalcSP(16);
          }

          .studio & {
            color: $c_studio;
          }

          .academy & {
            color: $c_academy;
          }
        }
      }

      &_data {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        @include responsive((
          width: 1576,
        ));
        @include mq(sp) {
          width: 100%;
        }
      }

      .btn {
        align-self: flex-end;
        @include responsive((
          width: 400,
          height: 112,
        ));
        @include mq(sp) {
          width: mycalcSP(400);
          height: mycalcSP(96);
          order: 3;
          margin: mycalcSP(32) auto 0;
        }
      }
    }

  }

  &_table {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    @include responsive((
      width: 1052,
      margin-right: 124,
    ));
    @include mq(sp) {
      width: 100%;
      margin-right: 0;
      order: 1;
    }
  }

  &_tr {
    @include responsive((
      width: 240,
      margin-right: 4,
    ));
    @include mq(sp) {
      width: 50%;
      margin-right: 0;
      box-sizing: border-box;

      &:nth-child(odd) {
        border-right: 1px solid $c_white;
      }

      &:nth-child(even) {
        border-left: 1px solid $c_white;
      }

      &:nth-child(n-2) {
        border-bottom: 1px solid $c_white;
      }

      &:nth-child(n+2) {
        border-top: 1px solid $c_white;
      }
    }

    &:last-of-type {
      margin-right: 0;
      @include responsive((
        width: 320,
      ));
      @include mq(sp) {
        width: 50%;
      }
    }
  }

  &_th {
    width: 100%;
    text-align: center;
    background: $c_gray;
    letter-spacing: 0;
    font-weight: bold;
    display: block;
    @include responsive((
      font-size: 32,
      line-height: 48,
      padding: 16 0,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(48);
      padding: mycalcSP(16 0);
    }

    small {
      letter-spacing: 0;
      font-weight: bold;
      @include responsive((
        font-size: 24,
        line-height: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(48);
      }
    }
  }

  &_td {
    width: 100%;
    text-align: center;
    background: $c_gray_light;
    letter-spacing: 0;
    font-weight: bold;
    display: block;
    @include responsive((
      font-size: 36,
      line-height: 80,
      padding: 40 0,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(48);
      padding: mycalcSP(32 0);
    }

    small {
      letter-spacing: 0;
      font-weight: bold;
      @include responsive((
        font-size: 28,
        line-height: 80,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(48);
      }
    }

  }

  &_desc {
    width: 100%;
    @include responsive((
      margin-top: 16,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(16);
      order: 2;
    }

    &_txt {
      font-weight: bold;
      letter-spacing: 0;
      @include responsive((
        font-size: 28,
        line-height: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(48);
      }
    }
  }

  &_notfound {
    @include responsive((
      margin-bottom: 48,
    ));
  }
}

.bnr {
  margin: 0 auto;
  @include responsive((
    width: 1960,
    margin-top: 24,
  ));
  @include mq(sp) {
    width: mycalcSP(670);
    margin-top: mycalcSP(24);
  }

  &_link {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;

    &_img {
      display: block;
      width: 100%;
      height: auto;

      &_inner {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        @include responsive((
          width: 1226,
          height: 400,
        ));
        @include mq(sp) {
          display: none;
        }
      }
    }

    &:hover {
      .bnr_link_img_inner {
        transform: scale(1.05);
      }
    }
  }
}