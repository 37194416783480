/*
 * breadcrumb.scss
 *
 * パンクズリスト用コンポーネント
 */

.breadcrumb {
  width: 100%;

  &_list {
    margin: 0 auto;
    @include flex(row, flex-start, center, none, wrap);
    @include responsive((
      width: 2400,
    ));
    @include mq(sp) {
      width: mycalcSP(660);
      margin: 0 auto;
    }

    &_item {

      &:not(:last-child) {
        &:after {
          content: " / ";
          color: $c_text;
          @include responsive((
            margin-right: 12,
            font-size: 24,
            line-height: 40,
          ));
          @include mq(sp) {
            margin-right: mycalcSP(12);
            font-size: mycalcSP(24);
            line-height: mycalcSP(40);
          }
        }
      }

      &_link {
        color: $c_pink;
      }

      &_txt {
        color: inherit;
        @include responsive((
          font-size: 24,
          line-height: 40,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(40);
        }
      }
    }
  }
}