/*
 * kv.scss
 *
 * キービジュアル用コンポーネント
 * 主にトップページで利用。
 */

.kv {
  width: 100%;
  margin: 0 auto;
  position: relative;
  @include responsive((
    padding-top: 1040,
    margin-bottom: 208,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(672);
    margin-bottom: mycalcSP(128);
  }

  &_headline {
    font-family: $f_point;
    font-weight: 700;
    letter-spacing: .1em;
    box-sizing: border-box;
    margin: 0 auto;
    @include responsive((
      font-size: 80,
      line-height: 128,
      margin-bottom: 64,
    ));
    @include responsiveFull((
      width: 3840,
      padding: 0 272,
    ));
    @include mq(wide) {
      margin-top: mycalcWide(80);
    }
    @include mq(sp) {
      font-size: mycalcSP(48);
      line-height: mycalcSP(96);
      margin-bottom: mycalcSP(64);
      padding: mycalcSP(0 40);
    }
  }

  &_desc {
    font-family: $f_point;
    font-weight: 700;
    letter-spacing: .1em;
    box-sizing: border-box;
    margin: 0 auto;
    @include responsive((
      font-size: 36,
      line-height: 80,
    ));
    @include responsiveFull((
      width: 3840,
      padding: 0 272,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(64);
      padding: mycalcSP(0 40);
    }
  }

  .em {
    color: $c_pink;
  }

  &_academy,
  &_studio {
    position: absolute;
    left: 50%;
    
    span {
      display: block;
      position: relative;
      width: 120%;
      height: 120%;
    }

    img {
      display: block;
      /*
      max-width: none;
      @include responsive((
        width: 1500,
        height: 960
      ));

      @include mq(sp) {
        width: 100%;
        height: auto;
      }
      */
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &_academy {
    @include responsive((
      width: 1180,
      height: 880,
      top: 160,
      margin-left: -764,
    ));
    @include mq(full) {
      margin-left: mycalcFull(-764);
    }
    @include mq(wide) {
      margin-left: mycalcWide(-584);
    }
    @include mq(sp) {
      width: mycalcSP(590);
      height: mycalcSP(440);
      top: mycalcSP(48);
      margin-left: mycalcSP(-295);
    }

    &_img {
      clip-path: url(#mask_academies);
      @include responsive((
        width: 1180,
        height: 880,
      ));
      @include mq(sp) {
        width: mycalcSP(590);
        height: mycalcSP(440);
      }
    }
  }

  &_studio {
    @include responsive((
      width: 880,
      height: 640,
      top: 976,
      margin-left: 660,
    ));
    @include mq(full) {
      margin-left: mycalcFull(660);
    }
    @include mq(wide) {
      margin-left: mycalcWide(460);
    }
    @include mq(sp) {
      width: mycalcSP(396);
      height: mycalcSP(288);
      top: mycalcSP(504);
      right: mycalcSP(28);
      margin-left: 0;
      left: auto;
    }

    svg {
      @include mq(sp) {
        width: mycalcSP(396);
        height: mycalcSP(288);
      }
    }

    &_img {
      clip-path: url(#mask_studios);
      @include responsive((
        width: 1200,
        height: 768,
      ));
      @include mq(sp) {
        width: mycalcSP(600);
        height: mycalcSP(384);
      }

      path {
        transform-origin: right bottom;
      }
    }
  }
}
