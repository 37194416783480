.live {
  .mv {
    @include responsive((
      margin-bottom: 240
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(120);
    }
  }

  .sub_sec {
    @include responsive((
      margin-bottom: 120
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(64);
    }
  }

  .sub_sec_list {
    margin-top: 0;
  }
}

.request_list,
.flow {
  @include responsive((
    margin: 240 auto,
  ));
  @include mq(sp) {
    margin: mycalcSP(192 auto);
  }
}

.flow {

  &_step {
    width: 100%;
    box-sizing: border-box;
    background: $c_white;
    @include responsive((
      padding: 96 80,
      border-radius: 64,
      margin-bottom: 64,
    ));
    @include mq(sp) {
      padding: mycalcSP(64 48);
      border-radius: mycalcSP(64);
      margin-bottom: mycalcSP(64);
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &_ttl {
    font-weight: 700;
    font-family: $f_point;
    position: relative;
    @include responsive((
      font-size: 48,
      line-height: 64,
      margin-bottom: 64,
      padding-bottom: 32,
    ));
    @include mq(sp) {
      letter-spacing: 0;
      font-size: mycalcSP(36);
      line-height: mycalcSP(58);
      margin-bottom: mycalcSP(32);
      padding-bottom: mycalcSP(32);
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $c_pink;
      @include responsive((
        height: 4,
      ));

      @include mq(sp) {
        height: mycalcSP(4);
      }
    }
  }

  &_desc {
    @include responsive((
      font-size: 32,
      line-height: 72,
      margin-bottom: 64,
    ));

    @include mq(sp) {
      font-size: mycalcSP(30);
      line-height: mycalcSP(64);
      margin-bottom: mycalcSP(32);
    }
  }

  &_link {
    @include responsive((
      font-size: 28,
      line-height: 56,
      margin-bottom: 64,
      padding-left: 32,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(56);
      margin-bottom: mycalcSP(64);
      padding-left: mycalcSP(32);
    }

    a {
      color: $c_text;
      border-color: $c_text;
      font-weight: bold;
      letter-spacing: 0;
    }
  }

  &_list {
    &_item {
      position: relative;
      color: $c_pink;
      font-weight: bold;
      @include responsive((
        padding-left: 32,
        font-size: 28,
        line-height: 56,
      ));
      @include mq(sp) {
        padding-left: mycalcSP(30);
        font-size: mycalcSP(28);
        line-height: mycalcSP(48);
      }

      &:not(:last-child) {
        @include responsive((
          margin-bottom: 16,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(16);
        }
      }

      &:before {
        content: "";
        background: $c_pink;
        border-radius: 50%;
        position: absolute;
        left: 0;
        @include responsive((
          width: 16,
          height: 16,
          top: 20,
        ));
        @include mq(sp) {
          width: mycalcSP(16);
          height: mycalcSP(16);
          top: mycalcSP(20);
        }
      }
    }
  }
}

.price {
  &_menu {
    @include responsive((
      margin-top: 64,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(64);
    }

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background: $c_white;
      width: 100%;
      box-sizing: border-box;
      @include responsive((
        padding: 64 128,
        border-radius: 96,
        margin-bottom: 32,
      ));
      @include mq(sp) {
        padding: mycalcSP(40);
        border-radius: mycalcSP(32);
        margin-bottom: mycalcSP(32);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_data {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      @include responsive((
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(32);
      }
    }

    &_list {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid $c_border;
      box-sizing: border-box;
      @include responsive((
        padding: 40,
      ));
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(40 0);
      }

      &:nth-child(even) {
        border-left: 1px solid $c_border;
        @include mq(sp) {
          border-left: 0;
        }
      }

      &.air {
        @include mq(sp) {
          display: none;
        }
      }
    }

    &_ttl {
      letter-spacing: 0;
      font-weight: bold;
      @include responsive((
        font-size: 32,
        line-height: 80,
      ));
      @include mq(sp) {
        letter-spacing: .1em;
        width: 100%;
        font-size: mycalcSP(32);
        line-height: mycalcSP(48);
        margin-bottom: mycalcSP(32);
      }
    }

    &_cost {
      @include mq(sp) {
        display: block;
        width: 100%;
        text-align: right;
      }
    }

    &_num {
      font-weight: bold;
      font-style: italic;
      @include responsive((
        font-size: 44,
        line-height: 80,
        margin: 0 24 0 12,
      ));
      @include mq(sp) {
        font-size: mycalcSP(64);
        line-height: mycalcSP(64);
        margin: mycalcSP(0 24 0 12);
      }
    }

    &_desc {
      width: 100%;
      letter-spacing: .1em;
      @include responsive((
        font-size: 30,
        line-height: 48,
        padding: 0 40 40,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(48);
        padding: mycalcSP(0 40 40);
      }
    }

  }

  .btn {
    @include responsive((
      margin-top: 64
    ));
    @include mq(sp) {
      margin-top: mycalcSP(64);
    }
  }
}