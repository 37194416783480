@use "sass:math";

.scrolled {
  &.header {
    transform: translateY(-96px);
    @include mq(pc) {
      transform: translateY(mycalc(-192));
    }

    .header_logo {
      transform: translateY(80px) scale(.9);
      @include mq(pc) {
        transform: translateY(mycalc(160)) scale(.9);
      }
    }

    .btn_circle {
      transform: translateY(80px) scale(.9);
      @include mq(pc) {
        transform: translateY(mycalc(160)) scale(.9);
      }
      @include mq(sp) {
        transform: translateY(0) scale(1);
      }
    }
  }
}

.clip_txt {
  //transform: translateY(50px);
  clip-path: inset(0% 0% 100% 0%);
  //opacity: 0;
  transition: clip-path .8s $e_slot,
  //opacity 2.6s cubic-bezier(.16, 1, .3, 1),
  //transform 2.6s cubic-bezier(.16, 1, .3, 1)
;

  &.is-active {
    clip-path: inset(0% 0% 0% 0%);
    //opacity: 1;
    //transform: translateY(0);
  }
}

.clip_circle {
  img {
    transform: translateY(50px);
    clip-path: circle(0% at 50% 10%);
    opacity: 0;
    transition: clip-path .8s $e_slot,
    opacity 2.6s cubic-bezier(.16, 1, .3, 1),
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  &.is-active {
    img {
      clip-path: circle(50% at 50% 50%);
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.clip_figure {
  img {
    transform: translateY(50px);
    clip-path: circle(0% at 50% 10%);
    //clip-path: inset(0% 0% 100% 0%);
    opacity: 0;
    transition: clip-path 1.2s $e_slot,
    opacity 2.6s cubic-bezier(.16, 1, .3, 1),
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  &.is-active {
    img {
      clip-path: circle(100% at 50% 50%);
      //clip-path: inset(0% 0% 0% 0%);
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.clip_svg {

  &.svg_bg_gourd {
    transform: translateY(50px);
    clip-path: circle(0% at 50% 10%);
    transition: clip-path 2.2s $e_slot,
    transform 2.6s cubic-bezier(.16, 1, .3, 1);

    &.is-active {
      clip-path: circle(100% at 50% 50%);
      transform: translateY(0);
    }
  }

  &.svg_keyboard {
    transform: translateY(50px);
    clip-path: circle(0% at 50% 10%);
    transition: clip-path 2.2s $e_slot,
    transform 2.6s cubic-bezier(.16, 1, .3, 1);

    &.is-active {
      clip-path: circle(100% at 50% 50%);
      transform: translateY(0);
    }
  }

}

.clip_mv {
  .mv_bg_img,
  .mv_bg:before {
    transform: translateY(50px);
    clip-path: inset(100% 0% 0% 0%);
    opacity: 0;
    transition: clip-path .8s $e_slot,
    opacity 2.6s cubic-bezier(.16, 1, .3, 1),
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  .mv_bg:before {
    transition: clip-path .8s $e_slot,
    opacity 2.6s cubic-bezier(.16, 1, .3, 1) .4s,
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }


  &.is-active {
    .mv_bg_img,
    .mv_bg:before {
      clip-path: inset(0% 0% 0% 0%);
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.svg_keyboard {
  .clip > & {
    transition: clip-path 1.4s $e_slot,
    transform 3.2s cubic-bezier(.16, 1, .3, 1);
    clip-path: inset(0% 0% 0% 100%);
    transform: translateX(250px) rotate(0deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translateX(0) rotate(-14.57deg);
  }
}

.svg_piano {
  &:nth-of-type(1) {
    .footer.clip > & {
      transition: clip-path 1.4s $e_draw,
      transform 3.2s cubic-bezier(.16, 1, .3, 1);
      clip-path: inset(0% 100% 0% 0%);
      transform: translateY(-100px) rotate(90deg);
    }
    .footer.is-active > & {
      clip-path: inset(0% 0% 0% 0%);
      transform: translateY(0) rotate(90deg);
    }
  }

  &:nth-of-type(2) {
    .footer.clip > & {
      transition: clip-path 1.4s $e_draw,
      transform 3.2s cubic-bezier(.16, 1, .3, 1);
      clip-path: inset(0% 0% 100% 0%);
      transform: translateY(-100px);
    }
    .footer.is-active > & {
      clip-path: inset(0% 0% 0% 0%);
      transform: translateY(0);
    }
  }
}

.svg_score {
  .clip > & {
    transition: clip-path 2s $e_draw .2s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1);
    clip-path: inset(0% 100% 100% 0%);
    transform: translateX(-250px) rotate(14deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translateX(0) rotate(0deg);
  }
}

.svg_treble_clef {
  .clip > & {
    transition: clip-path 2s $e_draw .4s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1) .2s;
    clip-path: inset(0% 0% 100% 0%);
    transform: translate(-250px, -100px) rotate(32deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translate(0, 0) rotate(0deg);
  }
}

.svg_eighth_note {
  .clip > & {
    transition: clip-path 2s $e_draw .5s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1) .3s;
    clip-path: inset(0% 100% 100% 0%);
    transform: translateX(-250px) rotate(64deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translateX(0) rotate(30.17deg);
  }
}

.svg_quarter_rest {
  .clip > & {
    transition: clip-path 2s $e_draw .6s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1) .4s;
    clip-path: inset(0% 100% 100% 0%);
    transform: translateX(-250px) rotate(40deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translateX(0) rotate(0deg);
  }
}

.svg_sixteenth_note {
  .clip > & {
    transition: clip-path 2s $e_draw .7s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1) .5s;
    clip-path: inset(0% 0% 0% 100%);
    transform: translateX(-250px) rotate(-30.08deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translateX(0) rotate(-100.08deg);
  }
}

.svg_note {
  .clip > & {
    transition: clip-path 2s $e_draw .8s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1) .6s;
    clip-path: inset(0% 100% 100% 0%);
    transform: translateX(-250px) rotate(45.01deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translateX(0) rotate(-15.01deg);
  }
}

.kv_headline {
  .clip > & {
    transition: clip-path 2s $e_draw .8s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1) .6s;
    clip-path: inset(0% 100% 100% 0%);
    transform: translateX(-250px) rotate(0deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translateX(0) rotate(0deg);
  }
}

.kv_desc {
  .clip > & {
    transition: clip-path 2s $e_draw .8s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1) .6s;
    clip-path: inset(0% 100% 100% 0%);
    transform: translateX(-250px) rotate(0deg);
  }

  .is-active > & {
    clip-path: inset(0% 0% 0% 0%);
    transform: translateX(0) rotate(0deg);
  }
}

.kv_studio,
.kv_academy {
  .clip > & {
    transition: clip-path 2s $e_draw .8s,
    transform 3.2s cubic-bezier(.16, 1, .3, 1) .6s;
    clip-path: circle(0% at 50% 10%);
    //transform: translateX(-250px) rotate(0deg);
  }

  .is-active > & {
    clip-path: circle(100% at 50% 50%);
    //transform: translateX(0) rotate(0deg);
  }
}

.offer_gourd {
  &.clip > a {
    transition: clip-path 1s $e_draw .4s,
    transform 1.6s cubic-bezier(.16, 1, .3, 1) .2s;
    clip-path: circle(0% at 50% 10%);
    transform: translateY(250px) rotate(20deg);

    .home & {
      transition: clip-path 1.2s $e_draw .4s,
      transform 1.8s cubic-bezier(.16, 1, .3, 1) .2s;
    }
  }

  &.is-active > a {
    clip-path: circle(100% at 50% 50%);
    transform: translateY(0) rotate(0deg);
  }
}
