.kv {
  position: relative;
  z-index: 0;

  .svg_treble_clef {
    position: absolute;
    z-index: -1;
    left: 50%;
    @include responsive((
      width: 924,
      height: 1368,
      top: -624,
      margin-left: -1168,
    ));
    @include mq(sp) {
      width: mycalcSP(258);
      height: mycalcSP(380);
      top: mycalcSP(-144);
      margin-left: mycalcSP(-207);
    }
  }

  .svg_eighth_note {
    position: absolute;
    z-index: -1;
    right: 50%;
    transform: rotate(30.17deg);
    transform-origin: center center;
    @include responsive((
      width: 748,
      height: 640,
      top: 284,
      margin-right: -1310,
    ));
    @include mq(sp) {
      width: mycalcSP(208);
      height: mycalcSP(178);
      top: mycalcSP(260);
      margin-right: mycalcSP(-229);
    }
  }

  .svg_quarter_rest {
    position: absolute;
    z-index: -1;
    right: 50%;
    @include responsive((
      width: 206,
      height: 602,
      top: 576,
      margin-right: -1710,
    ));
    @include mq(sp) {
      width: mycalcSP(58);
      height: mycalcSP(168);
      top: mycalcSP(440);
      margin-right: mycalcSP(-351);
    }
  }

  .svg_sixteenth_note {
    position: absolute;
    z-index: -1;
    right: 50%;
    transform: rotate(-100.08deg);
    transform-origin: center center;
    @include responsive((
      width: 472,
      height: 602,
      top: 1054,
      margin-right: -380,
    ));
    @include mq(sp) {
      width: mycalcSP(130);
      height: mycalcSP(168);
      top: mycalcSP(496);
      margin-right: mycalcSP(31);
    }
  }

  .svg_note {
    position: absolute;
    z-index: -1;
    right: 50%;
    transform: rotate(-15.01deg);
    transform-origin: center center;
    @include responsive((
      width: 442,
      height: 802,
      top: 1364,
      margin-right: -1104,
    ));
    @include mq(sp) {
      width: mycalcSP(122);
      height: mycalcSP(222);
      top: mycalcSP(724);
      margin-right: mycalcSP(-203);
    }
  }

  .svg_piano {
    position: absolute;
    z-index: -1;
    transform: rotate(-90deg);
    right: 50%;
    @include responsive((
      width: 2916,
      height: 2880,
      top: -780,
      margin-right: -1920,
    ));
    @include mq(sp) {
      width: mycalcSP(910);
      height: mycalcSP(898);
      top: mycalcSP(-144);
      margin-right: mycalcSP(-647);
    }
  }

  .svg_score {
    position: absolute;
    left: 50%;
    z-index: -1;
    opacity: .7;
    @include responsive((
      width: 4800,
      height: 3056,
      margin-left: -1200,
      top: -597,
    ));
    @include mq(sp) {
      transform: rotate(30.13deg);
      transform-origin: center center;
      width: mycalcSP(1680);
      height: mycalcSP(1070);
      margin-left: mycalcSP(-695);
      top: mycalcSP(20);
    }

    path {
      fill: $c_gray_pink;
    }
  }
}


.mv,
.mv_course_tax {
  position: relative;
  z-index: 0;

  .mv_course_tax_figure {
    z-index: -1;
  }

  .svg_treble_clef {
    position: absolute;
    z-index: -1;
    left: 50%;
    @include responsive((
      width: 924,
      height: 1368,
      top: 1718,
      margin-left: -382,
    ));
    @include mq(sp) {
      width: mycalcSP(258);
      height: mycalcSP(380);
      top: mycalcSP(596);
      margin-left: mycalcSP(-17);
    }
  }

  .svg_eighth_note {
    position: absolute;
    z-index: -1;
    right: 50%;
    transform: rotate(30.17deg);
    transform-origin: center center;
    @include responsive((
      width: 748,
      height: 640,
      top: 1840,
      margin-right: -1740,
    ));
    @include mq(sp) {
      width: mycalcSP(208);
      height: mycalcSP(178);
      top: mycalcSP(1000);
      margin-right: mycalcSP(-455);
      transform: rotate(-15deg);
    }
  }

  .svg_quarter_rest {
    position: absolute;
    z-index: -1;
    right: 50%;
    @include responsive((
      width: 206,
      height: 602,
      top: 2240,
      margin-right: -2148,
    ));
    @include mq(sp) {
      display: none;
    }
  }

  .svg_sixteenth_note {
    position: absolute;
    z-index: -1;
    right: 50%;
    transform: rotate(-100.08deg);
    transform-origin: center center;
    @include responsive((
      width: 472,
      height: 602,
      top: 2364,
      margin-right: -1026,
    ));
    @include mq(sp) {
      width: mycalcSP(130);
      height: mycalcSP(168);
      top: mycalcSP(1200);
      margin-right: mycalcSP(-168);
    }
  }

  .svg_note {
    position: absolute;
    z-index: -1;
    right: 50%;
    transform: rotate(-15.01deg);
    transform-origin: center center;
    @include responsive((
      width: 442,
      height: 802,
      top: 2920,
      margin-right: -1726,
    ));
    @include mq(sp) {
      width: mycalcSP(122);
      height: mycalcSP(222);
      top: mycalcSP(1462);
      margin-right: mycalcSP(-429);
    }
  }

  .svg_bg_gourd {
    position: absolute;
    right: 50%;
    z-index: -3;
    transform: scale(1, -1);
    @include responsive((
      width: 1760,
      height: 1280,
      margin-right: -1200,
      top: -852,
    ));
    @include mq(sp) {
      width: mycalcSP(588);
      height: mycalcSP(428);
      margin-right: mycalcSP(-446);
      top: mycalcSP(-244);
    }
  }

  .svg_score {
    position: absolute;
    left: 50%;
    z-index: -2;
    opacity: .7;
    @include responsive((
      width: 4800,
      height: 3056,
      margin-left: -2370,
      top: -2092,
    ));
    @include mq(sp) {
      transform: rotate(30.13deg);
      transform-origin: center center;
      width: mycalcSP(1680);
      height: mycalcSP(1070);
      margin-left: mycalcSP(-362);
      top: mycalcSP(-20);
    }

    path {
      fill: $c_gray_pink;
    }
  }
}


.offer_gourd {
  .svg_gourd_note {
    position: absolute;
    right: 50%;
    z-index: 1;
    @include responsive((
      width: 180,
      height: 160,
      top: 204,
      margin-right: -176,
    ));
    @include mq(sp) {
      width: mycalcSP(144);
      height: mycalcSP(128);
      top: mycalcSP(-6);
      margin-right: mycalcSP(-249);
    }
  }

  .offer_gourd_note {
    position: absolute;
    top: 0;
    left: 0;
    color: $c_accent;
    font-family: $f_accent;
    letter-spacing: 0;
    z-index: 1;
    transform: rotate(-11.11deg);
    @include responsive((
      font-size: 128,
      line-height: 128,
      top: 28,
      left: -40,
    ));
    @include mq(sp) {
      font-size: mycalcSP(96);
      line-height: mycalcSP(96);
      top: mycalcSP(8);
      left: mycalcSP(16);
    }
  }

  .anim_gourd_path {
    transition: fill $t_hv;
  }

  &_box {
    &:hover {
      .anim_gourd_path {
        fill: $c_gray_pink;

        .studio &,
        .academy & {
          fill: $c_gray_pink;
        }
      }
    }
  }
}


.top_concept,
.sub_sec {
  position: relative;
  z-index: 0;

  .svg_keyboard {
    position: absolute;
    right: 50%;
    z-index: -1;
    transform-origin: center center;
    transform: rotate(-14.57deg);
    @include responsive((
      width: 4540,
      height: 1266,
      margin-right: -2098,
      bottom: -86,
    ));
    @include mq(sp) {
      width: mycalcSP(2200);
      height: mycalcSP(614);
      margin-right: mycalcSP(-1175);
      bottom: mycalcSP(840);
    }

    .parents & {
      transform: scale(-1, 1);

      @include mq(sp) {
        bottom: 0;
      }
    }
  }
}


.top_course {
  position: relative;
  z-index: 1;

  .svg_guitar {
    position: absolute;
    right: 50%;
    z-index: -1;
    transform-origin: center center;
    transform: rotate(30.23deg);
    @include responsive((
      width: 3980,
      height: 1548,
      margin-right: -4080,
      top: 527,
    ));
    @include mq(sp) {
      width: mycalcSP(1032);
      height: mycalcSP(400);
      margin-right: mycalcSP(-839);
      top: mycalcSP(81);
    }
  }
}


.top_live {
  position: relative;
  z-index: 0;

  .svg_bg_gourd {
    position: absolute;
    right: 50%;
    z-index: -1;
    @include responsive((
      width: 2612,
      height: 1948,
      margin-right: -2052,
      bottom: -584,
    ));
    @include mq(sp) {
      width: mycalcSP(892);
      height: mycalcSP(664);
      margin-right: mycalcSP(-446);
      bottom: auto;
      top: mycalcSP(496);
    }
  }

  .svg_score {
    position: absolute;
    left: 50%;
    z-index: -1;
    opacity: .7;
    @include responsive((
      width: 4800,
      height: 3056,
      margin-left: -2744,
      top: -2138,
    ));
    @include mq(sp) {
      transform: rotate(30.13deg);
      transform-origin: center center;
      width: mycalcSP(1680);
      height: mycalcSP(1070);
      margin-left: mycalcSP(-811);
      top: mycalcSP(-604);
    }

    path {
      fill: $c_gray;
      @include mq(sp) {
        fill: $c_gray_pink;
      }
    }
  }

}


.course_english {
  position: relative;

  .svg_bg_gourd {
    position: absolute;
    right: 50%;
    z-index: -1;
    @include responsive((
      width: 2022,
      height: 1508,
      margin-right: -2240,
      top: 288,
    ));
    @include mq(sp) {
      width: mycalcSP(480);
      height: mycalcSP(360);
      margin-right: mycalcSP(-480);
      top: mycalcSP(180);
    }

    path {
      fill: $c_gray_light;
    }
  }

  .svg_piano {
    position: absolute;
    z-index: -1;
    transform: rotate(-90deg);
    left: 50%;
    @include responsive((
      width: 2720,
      height: 3840,
      top: -404,
      margin-left: -1360,
    ));
    @include mq(sp) {
      width: mycalcSP(750);
      height: mycalcSP(750);
      top: mycalcSP(60);
      left: 50%;
      margin-left: mycalcSP(-375);
    }
  }

  .course_list_item {
    position: relative;

    .course_list_item_note {
      position: absolute;
      top: 0;
      color: $c_accent;
      font-family: $f_accent;
      letter-spacing: 0;
      z-index: 1;
      transform: rotate(8.3deg);
      @include responsive((
        font-size: 192,
        line-height: 192,
        top: -120,
        right: -80,
      ));
      @include mq(sp) {
        font-size: mycalcSP(72);
        line-height: mycalcSP(72);
        top: mycalcSP(-48);
        right: mycalcSP(-8);
      }
    }
  }
}


.footer {
  position: relative;
  z-index: 0;
  overflow: hidden;

  .svg_piano {
    position: absolute;
    z-index: -1;
    @include responsive((
      width: 1728,
      height: 1750,
    ));
    @include mq(sp) {
      width: mycalcSP(652);
      height: mycalcSP(660);
    }

    &:nth-of-type(1) {
      transform: rotate(90deg);
      left: 50%;
      @include responsive((
        bottom: -504,
        margin-left: -2590,
      ));
      @include mq(sp) {
        display: none;
      }
    }

    &:nth-of-type(2) {
      right: 50%;
      @include responsive((
        bottom: -22,
        margin-right: -2366,
      ));
      @include mq(sp) {
        bottom: 0;
        margin-right: mycalcSP(-565);
      }
    }
  }
}