/*
 * mv.scss
 *
 * メインビジュアル用コンポーネント
 * 主に下層ページで利用。
 */

.mv {
  width: 100%;
  z-index: 0;
  position: relative;
  @include responsive((
    padding-top: 320,
    margin-bottom: 80,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(80);
    margin-bottom: mycalcSP(96);
  }

  &_headline {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include responsive((
      width: 2400,
      margin-bottom: 128,
    ));
    @include mq(sp) {
      width: mycalcSP(670);
      margin-bottom: mycalcSP(64);
    }

    &_en {
      order: 1;
      font-family: $f_en;
      color: $c_gray_pink;
      letter-spacing: 0;
      @include responsive((
        font-size: 192,
        line-height: 208,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(96);
        line-height: mycalcSP(96);
        margin-bottom: mycalcSP(48);
      }
    }

    &_ja {
      order: 2;
      letter-spacing: .1em;
      font-weight: bold;
      font-family: $f_point;
      @include responsive((
        font-size: 72,
        line-height: 96,
      ));
      @include mq(sp) {
        font-size: mycalcSP(44);
        line-height: mycalcSP(72);
      }
    }

    &_logo {
      order: -1;
      @include responsive((
        margin-bottom: 48,
      ));
      @include mq(sp) {
        height: mycalcSP(40);
        margin-bottom: mycalcSP(48);

        img {
          display: block;
        }
      }
    }
  }

  &_contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include responsive((
      width: 2400,
      padding-left: 32,
      margin: 0 auto,
    ));
    @include mq(sp) {
      box-sizing: border-box;
      width: mycalcSP(750);
      padding: mycalcSP(0 64);
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &_desc {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 1;
    @include responsive((
      width: 1280,
      padding: 0 32,
    ));
    @include mq(sp) {
      width: 100%;
      padding: 0;
    }

    &_copy {
      letter-spacing: .1em;
      font-weight: bold;
      font-family: $f_point;
      @include responsive((
        font-size: 64,
        line-height: 112,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        font-size: mycalcSP(40);
        line-height: mycalcSP(72);
        margin-bottom: mycalcSP(64);
      }

      .sub_copy {
        font-weight: bold;
        display: block;
        @include responsive((
          margin-top: 32,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(32);
        }
      }

      .em, em, strong{
        border-bottom: dashed $c_pink;
        color: $c_pink;
        font-weight: inherit;
        @include responsive((
          border-bottom-width: 4,
          padding-bottom: 6,
        ));
        @include mq(sp) {
          border-bottom-width: mycalcSP(4);
          padding-bottom: mycalcSP(6);
        }

        .studio & {
          border-color: $c_studio;
          color: $c_studio;
          font-weight: inherit;
        }

        .academy & {
          border-color: $c_academy;
          color: $c_academy;
          font-weight: inherit;
        }
      }

      small {
        letter-spacing: .1em;
        font-weight: inherit;
        @include responsive((
          font-size: 32,
          line-height: 56,
        ));
        @include mq(sp) {
          font-size: mycalcSP(20);
          line-height: mycalcSP(36);
        }
      }
    }

    &_txt {
      @include responsive((
        font-size: 32,
        line-height: 72,
        margin-bottom: 64,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(64);
        margin-bottom: mycalcSP(64);
      }

      &:last-child {
        margin-bottom: 0;
      }

      small {
        @include responsive((
          font-size: 24,
          line-height: 72,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(64);
        }
      }
    }

    .btn, .btn_back {
      @include responsive((
        margin-top: 128,
      ));
      @include mq(sp) {
        margin-top: mycalcSP(96);
      }
    }
  }

  &_figure {
    z-index: 0;
    box-sizing: border-box;
    border-radius: 50%;
    overflow: hidden;
    @include responsive((
      width: 1120,
      height: 1120,
    ));
    @include mq(sp) {
      width: mycalcSP(480);
      height: mycalcSP(480);
      margin-bottom: mycalcSP(80);
      order: -1;
    }

    &_img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

}

.mv_course_tax {
  position: relative;
  @include responsive((
    padding-top: 410,
    margin-bottom: 176,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(406);
    margin-bottom: mycalcSP(96);
  }

  &_desc {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 1;
    @include responsive((
      width: 2400,
      padding: 0 32,
    ));
    @include mq(sp) {
      width: mycalcSP(750);
      padding: mycalcSP(0 64);
    }

    &_copy {
      letter-spacing: .1em;
      font-weight: bold;
      font-family: $f_point;
      @include responsive((
        font-size: 64,
        line-height: 112,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        font-size: mycalcSP(40);
        line-height: mycalcSP(72);
        margin-bottom: mycalcSP(64);
      }

      .sub_copy {
        font-weight: bold;
        display: block;
        @include responsive((
          margin-top: 32,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(32);
        }
      }

      .em, em, strong {
        border-bottom: dashed $c_pink;
        @include responsive((
          border-bottom-width: 4,
          padding-bottom: 6,
        ));
        @include mq(sp) {
          border-bottom-width: mycalcSP(4);
          padding-bottom: mycalcSP(6);
        }

        .studio & {
          border-color: $c_studio;
        }

        .academy & {
          border-color: $c_academy;
        }
      }
    }

    &_txt {
      letter-spacing: 0;
      @include responsive((
        font-size: 32,
        line-height: 72,
        margin-bottom: 64,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(64);
        margin-bottom: mycalcSP(64);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_figure {
    position: absolute;
    right: 50%;
    z-index: 0;
    @include responsive((
      width: 1920,
      height: 1944,
      top: -28,
      margin-right: -2148,
    ));
    @include mq(sp) {
      width: mycalcSP(480);
      height: mycalcSP(486);
      top: mycalcSP(-128);
      margin-right: mycalcSP(-425);
    }

    &_img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.mv_post_headline,
.mv_course_tax_headline {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  @include responsive((
    width: 2400,
    margin-bottom: 128,
  ));
  @include mq(sp) {
    width: mycalcSP(670);
    margin-bottom: mycalcSP(96);
  }

  &_en {
    order: 1;
    font-family: $f_en;
    color: $c_gray_pink;
    letter-spacing: 0;
    @include responsive((
      font-size: 192,
      line-height: 208,
      margin-bottom: 16,
    ));
    @include mq(sp) {
      font-size: mycalcSP(96);
      line-height: mycalcSP(96);
      margin-bottom: mycalcSP(32);
    }
  }

  &_ja {
    order: 2;
    letter-spacing: .1em;
    font-weight: bold;
    font-family: $f_point;
    @include responsive((
      font-size: 48,
      line-height: 64,
    ));
    @include mq(sp) {
      font-size: mycalcSP(44);
      line-height: mycalcSP(72);
    }
  }

  &_logo {
    order: -1;
    @include responsive((
      margin-bottom: 48,
    ));
    @include mq(sp) {
      width: mycalcSP(448);
      margin-bottom: mycalcSP(48);

      img {
        display: block;
      }
    }
  }
}


.offer_gourd {
  box-sizing: border-box;
  @include responsive((
    width: 2400,
    margin: 0 auto 270,
    padding: 0 32,
  ));
  @include mq(sp) {
    width: 100%;
    margin: 0 auto mycalcSP(96);

    .home & {
      margin-bottom: mycalcSP(48);
    }
  }

  &_box {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include responsive((
      width: 1134,
      height: 718,
    ));
    @include mq(sp) {
      width: mycalcSP(670);
      height: auto;
      margin: 0 auto;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &_parent {
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    @include responsive((
      width: 664,
      padding: 168 0,
    ));
    @include mq(sp) {
      width: mycalcSP(560);
      height: mycalcSP(560);
      padding: mycalcSP(40);
      box-sizing: border-box;
      background: $c_pink;
      border-radius: 50%;
      justify-content: center;

      .studio & {
        background: $c_studio;
      }

      .academy & {
        background: $c_academy;
      }
    }
  }

  &_child {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include responsive((
      width: 392,
      height: 392,
      margin-top: 324,
      margin-left: 76,
    ));
    @include mq(sp) {
      width: mycalcSP(304);
      height: mycalcSP(304);
      margin-top: mycalcSP(-128);
      margin-right: 0;
      margin-left: auto;
      background: $c_pink;
      border-radius: 50%;

      .studio & {
        background: $c_studio;
      }

      .academy & {
        background: $c_academy;
      }
    }
  }

  &_ttl {
    color: $c_white;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    font-family: $f_point;
    @include responsive((
      font-size: 56,
      line-height: 96,
      margin-bottom: 32,
    ));
    @include mq(sp) {
      font-size: mycalcSP(40);
      line-height: mycalcSP(48);
      margin-bottom: mycalcSP(32);
    }
  }

  &_desc {
    color: $c_white;
    font-weight: bold;
    letter-spacing: 0;
    font-family: $f_point;
    @include responsive((
      font-size: 28,
      line-height: 56,
    ));
    @include mq(sp) {
      font-size: mycalcSP(26);
      line-height: mycalcSP(56);
      text-align: center;
    }
  }

  &_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $c_white;
    @include responsive((
      width: 256,
      height: 256,
    ));
    @include mq(sp) {
      width: mycalcSP(208);
      height: mycalcSP(208);
    }

    &_txt {
      text-align: center;
      color: $c_pink;
      letter-spacing: 0;
      font-weight: bold;
      @include responsive((
        font-size: 36,
        line-height: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(40);
      }

      .studio & {
        color: $c_studio;
      }

      .academy & {
        color: $c_academy;
      }
    }
  }

  &_circle {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    @include responsive((
      width: 392,
      height: 392,
    ));
    @include mq(sp) {
      width: mycalcSP(304);
      height: mycalcSP(304);
    }

    &_txt {
      color: $c_white;
      font-weight: bold;
      letter-spacing: 0;
      display: block;
      position: relative;
      @include responsive((
        width: 368,
        height: 368,
        font-size: 28,
        line-height: 48,
        top: 12,
        left: 12,
      ));
      @include mq(sp) {
        width: mycalcSP(300);
        height: mycalcSP(300);
        font-size: mycalcSP(24);
        line-height: mycalcSP(48);
        top: mycalcSP(2);
        left: mycalcSP(2);
      }

      > span {
        font-weight: inherit;
        letter-spacing: inherit;
        position: absolute;
        top: 0;
        display: inline-block;
        transform-origin: center bottom;
        left: calc(50% - .7rem); /* 中心点、、文字サイズ分ずらす */
        @include responsive((
          width: 28,
          height: 184,
        ));
        @include mq(pc) {
          left: calc(50% - #{mycalc(14)}); /* 中心点、、文字サイズ分ずらす */
        }
        @include mq(sp) {
          width: mycalcSP(24);
          height: mycalcSP(150);
          left: calc(50% - #{mycalcSP(12)}); /* 中心点、、文字サイズ分ずらす */
        }

        @for $i from 0 through 32 {
          &:nth-child(#{$i}) {
            $rotate: $i * 10.90;
            transform: rotate(#{$rotate}deg);
          }
        }
      }
    }
  }

  .gourd {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: visible;

    @include responsive((
      width: 1134,
      height: 718,
    ));

    @include mq(sp) {
      width: mycalcSP(1134);
      height: mycalcSP(718);
    }

    path {
      fill: $c_pink;

      .studio & {
        fill: $c_studio;
      }

      .academy & {
        fill: $c_academy;
      }
    }

    @include mq(sp) {
      display: none;
    }
  }
}