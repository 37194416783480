/*
 * transition.scss
 *
 * ページ遷移用コンポーネント
 */

.tt {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;

  .header_logo {
    z-index: 10;
    @include responsive((
      padding-top: 64,
    ));
    @include mq(sp) {
      padding-top: 0;
    }

    .tagline {
      color: $c_white;
    }
  }

  &_layer {
    @extend .tt;
  }
}