/*
 * header.scss
 *
 * ヘッダー用コンポーネント
 */

.header {
  width: 100%;
  position: fixed;
  z-index: 101;
  transition: transform $t_scroll;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include responsive((
    padding-top: 64,
  ));
  @include mq(sp) {
    height: mycalcSP(144);
    padding-top: 0;
  }

  &_logo {
    flex-shrink: 0;
    transition: transform $t_scroll;
    @include responsive((
      width: 336,
      margin-left: 96,
    ));
    @include mq(wide) {
      margin-left: mycalcFull(64);
    }
    @include mq(sp) {
      width: mycalcSP(252);
      margin-top: mycalcSP(36);
      margin-left: mycalcSP(40);
      padding: 0;
      z-index: 10;
    }

    &_link {
      @include flex(row, center, center, none, wrap);

      picture, svg, img {
        width: 100%;

        @include mq(sp) {
          height: auto;
        }
      }
    }

    .tagline {
      @include responsive((
        margin-top: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(20);
        line-height: mycalcSP(24);
        margin-top: mycalcSP(4);
      }
    }
  }

  &_gnav {
    text-align: center;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    position: relative;
    padding-bottom: 0;
    padding-right: 216px;
    transform: translateY(0);
    transition: transform $t_scroll;

    @media screen and (max-width: 1400px) {
      padding-right: mycalc(380);
    }

    @include mq(pc) {
      padding-right: mycalc(360);
    }

    @include mq(tb) {
      padding-right: mycalcWide(64);
    }

    @include mq(sp) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100vh;
      padding: mycalcSP(160 0 80);
      position: fixed;
      top: 0;
      color: $c_text;
      padding-bottom: 0;
      box-sizing: border-box;
      overflow-y: scroll;
      opacity: 0;
      transform: translateY(-100%);
      transition: opacity 1.0s $e_slot 0s, transform 1s $e_slot 1s;
      z-index: 9;
    }

    &_box {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-end;
      z-index: 6;
      @include mq(sp) {
        width: 100%;
        padding: 0;
        box-sizing: border-box;
        flex-shrink: 0;
      }
    }

    &_top {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-wrap: wrap;
      @include responsive((
        margin-bottom: 48,
      ));
      @media screen and (max-width: 1400px) {
        margin-bottom: mycalc(32);
      }
      @include mq(sp) {
        width: 100%;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: mycalcSP(48);
        padding-bottom: mycalcSP(48);
      }

      &_list {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-right: 40px;
        @include mq(wide) {
          margin-right: mycalcFull(80);
        }
        @include mq(pc) {
          margin-right: mycalc(60);
        }
        @include mq(sp) {
          flex-flow: column;
          justify-content: flex-start;
          align-items: center;
          margin: 0 auto mycalcSP(48);
          padding: 0;
          border-radius: 0;
          background: none;
        }

        &_item {
          @include mq(sp) {
            transform: translateY(mycalcSP(-250));
            opacity: 0;
            transition: opacity .8s $e_syu, transform .6s $e_syu;

            @for $i from 1 through 12 {
              &:nth-child(#{$i}) {
                transition-delay: .02s * $i + .1s;
              }
            }
          }

          &:not(:first-child) {
            margin-left: 24px;
            @include mq(wide) {
              margin-left: mycalcFull(48);
            }
            @include mq(pc) {
              margin-left: mycalc(32);
            }
            @include mq(sp) {
              position: relative;
              margin: mycalcSP(32 auto 0);
            }
          }

          &_link {
            letter-spacing: 0;
            font-weight: bold;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            @include responsive((
              font-size: 28,
              line-height: 40,
            ));
            @media screen and (max-width: 1400px) {
              font-size: mycalc(24);
              line-height: mycalc(44);
            }
            @include mq(sp) {
              box-sizing: border-box;
              background: $c_white;
              justify-content: center;
              font-size: mycalcSP(28);
              line-height: mycalcSP(48);
              padding: mycalcSP(40 48);
              min-width: mycalcSP(396);
              border-radius: mycalcSP(64);
            }

            &_txt {
              font-weight: bold;
            }

            svg {
              margin-right: 8px;
              @include responsive((
                width: 32,
                height: 32,
              ));
              @include mq(pc) {
                margin-right: mycalc(8);
              }
              @include mq(sp) {
                width: mycalcSP(32);
                height: mycalcSP(32);
                margin-right: mycalcSP(16);
              }
            }
          }
        }
      }

      &_contact {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        @include mq(sp) {
          width: 100%;
          flex-wrap: wrap;
          flex-flow: column;
          justify-content: flex-start;
          align-items: center;

          .tel {
            width: mycalcSP(640);
            order: 3;
            transform: translateY(mycalcSP(-250));
            opacity: 0;
            transition: opacity .8s $e_syu, transform .6s $e_syu;
            transition-delay: .22s;
          }

          .mail {
            order: 2;
            margin-bottom: mycalcSP(32);
            transform: translateY(mycalcSP(-250));
            opacity: 0;
            transition: opacity .8s $e_syu, transform .6s $e_syu;
            transition-delay: .20s;
          }
        }
        @include minq(sp) {
          .mail .ico_arw {
            display: none;
          }
          .mail_link_txt_way {
            display: none;
          }
        }

        .btn_circle {
          position: absolute;
          right: 0;
          top: 0;

          @include mq(tb) {
            top: auto;
            bottom: 0;
            width: mycalc(400);
            height: mycalc(128);
            border-radius: mycalc(80);
            margin-right: mycalcWide(64);

            br {
              display: none;
            }
          }

          @include mq(sp) {
            order: 1;
            position: relative;
            width: mycalcSP(640);
            height: mycalcSP(112);
            margin: 0 auto mycalcSP(48);
            border-radius: mycalcSP(64);
            transform: translateY(mycalcSP(-250));
            opacity: 0;
            transition: opacity .8s $e_syu, transform .6s $e_syu;
            transition-delay: .18s;
          }
        }
      }
    }

    &_bottom {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      @include mq(sp) {
        order: -1;
      }

      &_list {
        transition: $t_scroll;
        background: $c_gray_light;
        @include flex(row, center, center, none, wrap);
        @include responsive((
          border-radius: 64,
          padding: 0 64,
        ));
        @include mq(tb) {
          margin-right: mycalc(432);
        }
        @include mq(sp) {
          margin: 0 auto;
          border-radius: 0;
          padding: 0;
          background: none;
        }

        &_item {
          @include responsive((
            margin: (0, 0),
          ));
          @include mq(sp) {
            transform: translateY(mycalcSP(-250));
            opacity: 0;
            transition: opacity .8s $e_syu, transform .6s $e_syu;
            width: 100%;
            margin: 0;
            border-bottom: 1px solid $c_gray;
            @for $i from 1 through 12 {
              &:nth-child(#{$i}) {
                transition-delay: .02s * $i;
              }
            }
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &_link {
            font-weight: bold;
            line-height: 1;
            position: relative;
            transition: background-color $t_slide, color $t_scroll;
            box-sizing: border-box;
            color: $c_text;
            letter-spacing: 0;
            @include flex(row, center, center, none, wrap);
            @include responsive((
              font-size: 32,
              line-height: 48,
              height: 128,
              padding: 0 32,
            ));
            @media screen and (max-width: 1400px) {
              font-size: mycalc(26);
              line-height: mycalc(32);
              padding: mycalc(0 24);
            }
            @include mq(sp) {
              justify-content: space-between;
              padding: mycalcSP(48 40);
              font-size: mycalcSP(32);
              line-height: mycalcSP(48);
              height: auto;
              color: $c_text;
              text-align: left;
            }

            &_txt {
              letter-spacing: inherit;
              font-weight: inherit;
            }

            .ico_add {
              display: none;
              @include mq(sp) {
                display: block;
                width: mycalcSP(48);
                height: mycalcSP(48);
              }
              &_vertical {
                @include mq(sp) {
                  stroke: $c_pink;
                  stroke-dasharray: 24;
                  stroke-width: 0;
                  fill: $c_pink;
                  stroke-dashoffset: 0;
                  transition: fill $t_slide .8s,
                  stroke-width $t_slide .8s,
                  stroke-dashoffset $t_slide;
                }
              }
            }

            &.active {
              .ico_add {
                &_vertical {
                  @include mq(sp) {
                    stroke-width: .5;
                    fill: transparent;
                    stroke-dashoffset: 24px;
                    transition: fill $t_slide,
                    stroke-width $t_slide,
                    stroke-dashoffset $t_slide .8s;
                  }
                }
              }
            }

            &.open {
              background: $c_gray_light;
            }

            &:before {
              content: "";
              width: 100%;
              height: 100%;
              opacity: 0;
              background: $c_gray_light;
              bottom: 0;
              left: 0;
              display: block;
              position: absolute;
              transition: $t_slide;
              z-index: -1;
            }

            &:hover {
              background: $c_gray_light;

              &:before {
                opacity: 1;
              }
            }
          }
        }

        .gnav_list_parent {

          .header_gnav_list_item_link {
            position: relative;
            @include responsive((
              padding-right: 92,
            ));

            &:after {
              content: '';
              border-left: solid 2px $c_black;
              border-bottom: solid 2px $c_black;
              position: absolute;
              top: 50%;
              transition: $t_slide;
              transform: rotate(-45deg);
              box-sizing: border-box;
              @include responsive((
                width: 12,
                height: 12,
                right: 48,
                margin-top: -6,
              ));
              @include mq(sp) {
                width: mycalcSP(12);
                height: mycalcSP(12);
                right: mycalcSP(48);
                margin-top: mycalcSP(-8);
              }
            }

            &:hover:after {
            }
          }
        }
      }

      &_children {
        position: absolute;
        right: 0;
        width: 100vw;
        overflow: hidden;
        z-index: -1;
        visibility: hidden;
        @include responsive((
          min-height : 480,
          top: 136,
        ));
        @include mq(sp) {
          display: none;
          position: relative;
          height: auto;
          top: 0;
          min-height: auto;
        }

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 0;
          z-index: -1;
          background: $c_gray_light;
          position: absolute;
          top: 0;
          left: 0;
          transition: $t_slide_child 0s;

          @include mq(sp) {
            background: $c_gray_light;
          }
        }

        &.open {
          visibility: visible;
          max-height: 100vh;
          z-index: 6;

          @include responsive((
            min-height : 560,
          ));

          @include mq(sp) {
            display: block;
            max-height: none;
            min-height: auto;
          }

          &:after {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition-delay: .04s;
          }

          .header_gnav_child {
            transition: opacity 0.4s $e_slot, transform 0.6s $e_slot;
            transform: translateY(0);
            opacity: 1;

            @for $i from 1 through 5 {
              &:nth-child(#{$i}) {
                transition-delay: .1s + (.02s * $i);
              }
            }
          }
        }

        &_list {
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          @include responsive((
            width: 1836,
          ));
          @include mq(sp) {
            width: 100%;
          }

          &_item {
            position: relative;
            text-align: left;
            @include responsive((
              width: 564,
              margin-left: 48,
              margin-bottom: 32,
            ));
            @include mq(sp) {
              width: mycalcSP(670);
              margin: mycalcSP(0 auto 48);
              @for $i from 1 through 12 {
                &:nth-child(#{$i}) {
                  transition-delay: .05s + (.05s * $i);
                }
              }
            }

            &_link {
              color: $c_text;
              display: block;
              letter-spacing: 0;
              @include textGradient($c_text, $c_pink, '.header_gnav_child_list_item_txt');
              @include responsive((
                padding: 4 0,
                font-size: 28,
                line-height: 36,
              ));
              @include mq(sp) {
                font-size: mycalcSP(28);
                line-height: mycalcSP(36);
                padding: mycalcSP(4 0);
              }
            }

            &_txt {
              color: $c_white;
              font-weight: inherit;

            }
          }
        }
      }
    }

    &_filter {
      position: fixed;
      width: 100%;
      height: 100%;
      background: $c_white;
      transform: translateY(110%);
      opacity: .7;
      top: 0;
      left: 0;
      z-index: 5;
      transition: .6s $e_slot .02s;

      &.open {
        transition: .6s $e_slot;
      }

      @include mq(sp) {
        display: none;
      }
    }

  }


}

.hum {
  display: none;

  @include mq(sp) {
    @include flex(column, center, center, none, wrap);
    align-self: stretch;
    width: mycalcSP(144);
    height: mycalcSP(144);
    background: $c_gray_light;
    color: $c_pink;
    transition: .5s;
    cursor: pointer;
    font-weight: 500;
    order: 1;
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    border-radius: mycalcSP(0 0 0 72);

    &:hover {
      cursor: pointer;
    }

    &_bar {
      width: mycalcSP(40);
      height: mycalcSP(28);
      margin-bottom: mycalcSP(14);
      position: relative;

      &_u,
      &_m,
      &_b {
        position: absolute;
        height: mycalcSP(4);
        background: $c_pink;
        width: 100%;
        right: 0;
        border-radius: mycalcSP(20);
        transition: all .3s, background-color $p_hum;
      }

      &_u {
        top: 0;
      }

      &_m {
        top: 50%;
        margin-top: mycalcSP(-2);
      }

      &_b {
        bottom: 0;
      }
    }

    &_bg {
      content: "";
      display: block;
      position: fixed;
      background: $c_gray_light;
      z-index: 2;
      transform: translate(calc(100% - #{mycalcSP(144)}), calc(-100% + #{mycalcSP(144)}));
      will-change: animation, transform;
      width: 100%;
      height: 100%;
      border-radius: mycalcSP(0 0 0 72);
      top: 0;
      right: 0;
      transform-origin: top right;
    }

    &_menu {
      z-index: 1;
      svg {
        width: mycalcSP(60);
        height: mycalcSP(18);
        display: block;
        transition: $t_fade;

        path {
          fill: $c_pink;
          fill-opacity: 1;
          stroke: $c_pink;
          stroke-width: 0;
          stroke-dasharray: 54;
          stroke-dashoffset: 0;
          transition: fill $t_slide 1.8s,
          fill-opacity $t_fade 1.8s,
          stroke-width $t_slide 1.8s,
          stroke-dashoffset $t_slide 1s;
        }
      }
    }

    &_close {
      @include flex(row, center, center, none, wrap);
      position: absolute;
      width: 100%;
      right: 0;
      bottom: mycalcSP(42);
      z-index: 0;

      svg {
        width: mycalcSP(58);
        height: mycalcSP(18);
        display: block;
        transition: $t_fade;

        path {
          fill: transparent;
          fill-opacity: 1;
          stroke: $c_pink;
          stroke-width: .5;
          stroke-dasharray: 54;
          stroke-dashoffset: 54px;
          transition: fill $t_slide,
          fill-opacity $t_slide,
          stroke-width $t_slide,
          stroke-dashoffset $t_slide .8s;
        }
      }
    }
  }
}

.open {
  @include mq(sp) {
    .hum {
      &_bar {
        &_u,
        &_m,
        &_b {
          background: $c_pink;
        }

        &_u {
          animation: Humberger_bar_z $p_hum*1.8 $e_syu .2s forwards;
        }

        &_m {
          animation: Humberger_bar_x $p_hum*1.8 $e_syu .2s forwards;
        }

        &_b {
          animation: Humberger_bar_y $p_hum*1.8 $e_syu .2s forwards;
        }
      }

      &_bg {
        transform: translate(0%, 0%);
        animation: Humberger_bg .6s $e_curtain 0s forwards;
      }

      &_menu path {
        fill: transparent;
        fill-opacity: 1;
        stroke-width: .5;
        stroke-dashoffset: 54px;
        transition: fill $t_slide,
        fill-opacity $t_slide,
        stroke-width $t_slide,
        stroke-dashoffset $t_slide .8s;
      }

      &_close {
        z-index: 2;
        path {
          fill: $c_pink;
          fill-opacity: 1;
          stroke-width: 0;
          stroke-dashoffset: 0;
          transition: fill $t_slide 1.8s,
          fill-opacity $t_fade 1.8s,
          stroke-width $t_slide 1.8s,
          stroke-dashoffset $t_slide 1s;
        }
      }
    }

    &.header_gnav {
      opacity: 1;
      height: 100vh;
      overflow-y: scroll;
      transform: translateY(0);
      transition: transform $p_hum, opacity 1.5s;

      .header_gnav_bottom_list {
        background: transparent;

        &_item {
          transform: translateY(0);
          opacity: 1;

          @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
              transition-delay: .1s + (.02s * $i);
            }
          }
        }
      }

      .header_gnav_top_list {
        background: transparent;

        &_item {
          transform: translateY(0);
          opacity: 1;

          @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
              transition-delay: .1s + (.02s * $i);
            }
          }
        }
      }

      .btn_circle {
        transform: translateY(0);
        opacity: 1;
        transition-delay: .18s;
      }

      .tel {
        transform: translateY(0);
        opacity: 1;
        transition-delay: .2s;
      }

      .mail {
        transform: translateY(0);
        opacity: 1;
        transition-delay: .22s;
      }

      .header_gnav_offer {
        transition-delay: .2s;
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.close {
  @include mq(sp) {
    .hum {
      &_bar {
        &_u,
        &_m,
        &_b {
          border-color: $c_white;
        }

        &_u {
          animation: Humberger_bar_z_rev $p_hum*2.2 $e_syu 0s forwards;
        }

        &_m {
          animation: Humberger_bar_x_rev $p_hum*2.2 $e_syu 0s forwards;
        }

        &_b {
          animation: Humberger_bar_y_rev $p_hum*2.2 $e_syu 0s forwards;
        }
      }

      &_bg {
        transform: translate(0, 0);
        animation: Humberger_bg_rev 0.6s $e_curtain .25s forwards;
      }
    }
  }
}

.is-fixed {
  @include mq(sp) {
    height: 100%;
    overflow: hidden;
  }
}