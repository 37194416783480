/*
 * box.scss
 *
 * 箱型デザイン用コンポーネント
 * 定義する際は、接頭辞としてbox_を付けること。
 */

@mixin box_card() {
  display: block;
  @include responsive((
    width: 564,
    margin: (80, auto),
  ));
  @include mq(sp) {
    width: mycalcSP(640);
    margin: mycalcSP(64 auto);
  }

  &_meta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include responsive((
      margin-bottom: 40,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(40);
    }

    .time {
      font-family: $f_en;

      @include responsive((
        font-size: 26,
        line-height: 26,
      ));
      @include mq(sp) {
        font-size: mycalcSP(26);
        line-height: mycalcSP(26);
      }
    }

    .tag {
      @include responsive((
        font-size: 24,
        line-height: 42,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(42);
      }
    }
  }

  &_data {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    @include responsive((
      padding: 48,
    ));
    @include mq(pc) {
      height: calc(100% - #{mycalc(376)});
    }
    @include mq(sp) {
      padding: mycalcSP(48);
    }

    .btn {
      width: 100%;
    }
  }

  &_desc {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &_copy {
      order: -1;

      @include responsive((
        font-size: 24,
        line-height: 36,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(36);
        margin-bottom: mycalcSP(16);
      }
    }

    &_ttl {
      font-weight: bold;

      @include responsive((
        font-size: 36,
        line-height: 48,
        margin-bottom: 40,
      ));
      @include mq(sp) {
        font-size: mycalcSP(36);
        line-height: mycalcSP(48);
        margin-bottom: mycalcSP(40);
      }
    }

    &_txt {

      @include responsive((
        font-size: 26,
        line-height: 44,
      ));
      @include mq(sp) {
        font-size: mycalcSP(26);
        line-height: mycalcSP(44);
      }
    }
  }

  &_thumb {
    order: -1;
    overflow: hidden;
    @include responsive((
      width: 564,
      height: 376,
    ));
    @include mq(sp) {
      width: 100%;
      height: auto;
    }

    &_img {
      width: 100%;
      height: auto;
      display: block;
      transition: transform $t_hv;
    }
  }

  .btn {
    @include responsive((
      height: 80,
      margin: 40 0 0,
    ));
    @include mq(sp) {
      width: 100%;
      height: mycalcSP(80);
      margin: mycalcSP(40 0 0);
    }

    &_link {
      justify-content: center;
      @include responsive((
        padding: 0 32,
      ));
      @include mq(sp) {
        padding: mycalcSP(0 32);
      }

      &_txt {
        @include responsive((
          font-size: 26,
          line-height: 44,
        ));
        @include mq(sp) {
          font-size: mycalcSP(26);
          line-height: mycalcSP(44);
        }
        &:before,
        &:after {
          @include responsive((
            right: 24,
          ));
          @include mq(sp) {
            right: mycalcSP(24);
          }
        }
      }
    }
  }

  &_link {
    height: 100%;
    background: $c_white;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: filter $t_hv;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.05));
    @include mq(pc) {
      filter: drop-shadow(#{mycalc(0 0 48)} rgba(0, 0, 0, 0.05));
    }
    @include mq(sp) {
      filter: drop-shadow(#{mycalc(0 0 48)} rgba(0, 0, 0, 0.05));
    }

    &:hover {
      filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.15));
      @include mq(pc) {
        filter: drop-shadow(#{mycalc(0 0 64)} rgba(0, 0, 0, 0.15));
      }
      @include mq(sp) {
        filter: drop-shadow(#{mycalc(0 0 64)} rgba(0, 0, 0, 0.15));
      }

      .box_card_thumb_img {
        transform: scale(1.1);
      }
    }
  }

}


// 利用例
.box_card {
  @include box_card;
}