.top {

  // Concept
  &_concept {
    @include responsive((
      margin-bottom: 240,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(192);
    }

    &_ttl {
      width: 100%;
      box-sizing: border-box;
      font-weight: bold;
      letter-spacing: 0;
      font-family: $f_point;
      @include responsive((
        font-size: 64,
        line-height: 128,
        margin-bottom: 64,
        padding: 0 32,
      ));
      @include mq(sp) {
        font-size: mycalcSP(40);
        line-height: mycalcSP(80);
        margin-bottom: mycalcSP(64);
        padding: mycalcSP(0 24);
      }
    }

    &_desc {
      width: 100%;
      box-sizing: border-box;
      @include responsive((
        font-size: 32,
        line-height: 80,
        padding: 0 32,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(72);
        padding: mycalcSP(0 24);
        letter-spacing: 0;
      }
    }

    &_list {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      @include responsive((
        margin-top: 96,
        padding: 0 32,
      ));
      @include mq(sp) {
        margin-top: mycalcSP(96);
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
      }

      .parents & {
        justify-content: center;
        @include responsive((
          margin-top: 480,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(192);
        }
      }

      &_item {
        @include responsive((
          width: 1112,
          margin: 0 40,
        ));
        @include mq(sp) {
          width: mycalcSP(670);
          margin: mycalcSP(0 auto 64);
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
          @include mq(sp) {
            margin-bottom: 0;
          }
        }

        &_figure {
          position: relative;
          overflow: hidden;
          box-sizing: border-box;
          @include responsive((
            width: 1112,
            height: 624,
            border-radius: 96,
            padding: 16,
          ));
          @include mq(sp) {
            width: mycalcSP(670);
            height: mycalcSP(444);
            border-radius: mycalcSP(96);
            padding: mycalcSP(16);
          }

          &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            border: $c_white solid;
            @include responsive((
              border-radius: 96,
              border-width: 32,
            ));
            @include mq(sp) {
              border-radius: mycalcSP(96);
              border-width: mycalcSP(32);
            }
          }

          &_img {
            @include responsive((
              width: 1080,
              height: 592,
              border-radius: 96,
            ));
            @include mq(sp) {
              width: mycalcSP(638);
              height: mycalcSP(412);
              border-radius: mycalcSP(96);
            }
          }

          .course_list_item_bnr_data {
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .ico_logo_studio,
        .ico_logo_academy {
          position: absolute;
          top: 50%;
          left: 50%;
        }

        .ico_logo_studio {
          @include responsive((
            width: 388,
            height: 128,
            margin-top: -64,
            margin-left: -194,
          ));
          @include mq(sp) {
            width: mycalcSP(388);
            height: mycalcSP(128);
            margin-top: mycalcSP(-64);
            margin-left: mycalcSP(-194);
          }
        }

        .ico_logo_academy {
          @include responsive((
            width: 454,
            height: 128,
            margin-top: -64,
            margin-left: -227,
          ));
          @include mq(sp) {
            width: mycalcSP(454);
            height: mycalcSP(128);
            margin-top: mycalcSP(-64);
            margin-left: mycalcSP(-227);
          }
        }
      }
    }

    .btn {
      margin: 0 0 0 auto;
      transform: translate(16px, -40px);
      @include mq(pc) {
        transform: translate(#{mycalc(32)}, #{mycalc(-80)});
      }
      @include mq(sp) {
        transform: none;
        width: mycalcSP(560);
        height: mycalcSP(112);
        margin: mycalcSP(-64 auto 0);
      }
    }
  }


  // Course list.
  &_course {
    @include responsive((
      margin-bottom: 180,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(192);
    }

    .ttl_sec {
      align-items: flex-end;
      @include responsive((
        margin-bottom: 84,
      ));

      @include mq(sp) {
        margin-bottom: mycalcSP(100);
        align-items: flex-start;
      }
    }

    &_list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: flex-start;
      width: 100%;

      /*
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
      $sliderPadding: calc((100vw - 1200px) / 2);
      padding-left: calc($sliderPadding + 16px);
      width: calc(100vw - $sliderPadding);
      transform: translateX(calc($sliderPadding * -1));
      */


      /*
      @include mq(pc) {
        $sliderPadding: calc((100vw - #{mycalc(2400)}) / 2);
        padding-left: calc($sliderPadding + #{mycalc(32)});
        width: calc(100vw - $sliderPadding);
        transform: translateX(calc($sliderPadding * -1));
      }
      */

      @include mq(sp) {
        width: 100vw;
        padding-left: 0;
        transform: none;
        padding-right: 0;
      }

      &_item {
        @include responsive((
          margin-right: 48,
        ));
        @include mq(sp) {
          margin-right: mycalcSP(48);
        }
        /*
        &:last-child {
          @include responsive((
            margin-right: 96,
          ));
          @include mq(sp) {
            margin-right: mycalcSP(96);
          }
        }
        */

        &_link {
          flex-flow: column;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &_figure {
          order: -1;
          overflow: hidden;
          position: relative;
          z-index: 0;
          background: $c_pink_light;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          @include responsive((
            width: 624,
            height: 624,
            border-radius: 96,
            border-width: 32,
            padding: 16,
          ));
          @include mq(sp) {
            width: mycalcSP(400);
            height: mycalcSP(400);
            border-radius: mycalcSP(96);
            border-width: mycalcSP(32);
            padding: mycalcSP(16);
          }

          &_img {
            display: block;
            @include responsive((
              width: 592,
              height: 592,
              border-radius: 96,
            ));
            @include mq(sp) {
              width: mycalcSP(368);
              height: mycalcSP(368);
              border-radius: mycalcSP(96);
            }
          }

          &_ico {
            @include mq(sp) {
              width: auto;
              height: mycalcSP(96);
            }
          }

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            border: $c_white solid;
            top: 0;
            left: 0;
            box-sizing: border-box;
            @include responsive((
              border-radius: 96,
              border-width: 32,
            ));
            @include mq(sp) {
              border-radius: mycalcSP(96);
              border-width: mycalcSP(32);
            }
          }
        }

        .btn {
          background: $c_white;
          border-color: $c_white;
          z-index: 1;
          @include responsive((
            width: 560,
            height: 128,
            border-radius: 64,
            margin-top: -64,
          ));
          @include mq(sp) {
            width: mycalcSP(400);
            height: mycalcSP(96);
            border-radius: mycalcSP(64);
            margin-top: mycalcSP(16);
            text-align: center;
          }

          &_link {
            color: $c_text;
            @include textGradient($c_text, $c_text, '.btn_link_txt', $p_slide, .1s);

            &_txt {
              @include responsive((
                font-size: 32,
                line-height: 48,
              ));
              @include mq(sp) {
                font-size: mycalcSP(28);
                line-height: mycalcSP(36);
              }
            }

            .ico_arw path{
              fill: $c_pink;
            }

            &:after {
              background: $c_pink_light;
            }
          }
        }
      }
    }

    &_instrument {
      position: relative;
      @include responsive((
        margin-bottom: 160,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(160);
      }
    }

    &_purpose {
      position: relative;
      @include responsive((
        margin-bottom: 160,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(96);
      }
    }

    &_btn {
      margin: 0 auto;
    }
  }


  // Live performance.
  &_live {
    position: relative;

    @include responsive((
      margin-bottom: 528,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(192);
    }

    .ttl_sec {
      @include responsive((
        margin-bottom: 64,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(96);
      }
    }

    .sec_desc {
      @include responsive((
        width: 1144,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        width: 100%;
        letter-spacing: 0;
        font-size: mycalcSP(30);
        line-height: mycalcSP(72);
        margin-bottom: mycalcSP(64);
      }
    }

    .btn {
      @include responsive((
        margin-left: 32,
      ));
      @include mq(sp) {
        margin: 0 auto;
      }
    }

    .figure_circle {
      position: absolute;
      right: 50%;
      @include responsive((
        top: 352,
        margin-right: -1200,
      ));
      @include mq(sp) {
        width: mycalcSP(640);
        position: relative;
        top: 0;
        right: 0;
        margin: mycalcSP(0 auto 64);
      }
    }
  }

}


.swiper {
  overflow: visible;
  $sliderPadding: calc((100vw - 1200px) / 2);
  width: calc(100vw - $sliderPadding);

  @include mq(pc) {
    $sliderPadding: calc((100vw - #{mycalc(2400)}) / 2);
    width: calc(100vw - $sliderPadding);
  }
  @include mq(sp) {
    width: 100%;
  }

  .arw {
    &_prev,
    &_next {
      position: absolute;
      cursor: pointer;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity $t_fade;
      right: 50%;
      @include responsive((
        top: 16,
        margin-right: -1200,
        width: 48,
        height: 48,
      ));
      @include mq(sp) {
        width: mycalcSP(48);
        height: mycalcSP(48);
        top: mycalcSP(4);
        right: mycalcSP(0);
        margin-right: 0;
      }

      .ico_arw {
        @include mq(sp) {
          width: mycalcSP(48);
          height: mycalcSP(48);
        }

        path {
          stroke: $c_pink;
        }
      }

      .top_course_purpose & {
        @include mq(sp) {
          top: mycalcSP(52);
        }
      }
    }

    &_prev {
      @include responsive((
        margin-right: -1128,
      ));
      @include mq(sp) {
        margin-right: 0;
        right: mycalcSP(72);
      }
      .ico_arw {
        transform: rotate(180deg);
      }
    }

    &_next {
    }

  }

  .swiper-scrollbar {
    position: relative;
    width: calc(100vw - ((100vw - 1200px)*.5));
    @include responsive((
      height: 10,
      margin-top: 64,
    ));
    @include mq(pc) {
      width: calc(100vw - ((100vw - #{mycalc(2400)})*.5));
    }
    @include mq(sp) {
      width: calc(100vw - #{mycalcSP(48)});
      height: mycalcSP(10);
      margin-left: 0;
      margin-top: mycalcSP(64);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      background: $c_white;
      @include responsive((
        height: 6,
        top: 5,
        border-radius: 20,
      ));
      @include mq(sp) {
        height: mycalcSP(6);
        top: mycalc(5);
        border-radius: mycalcSP(20);
      }
    }

    &-drag {
      cursor: pointer;
      position: relative;
      @include responsive((
        height: 10,
      ));
      @include mq(sp) {
        height: mycalcSP(10);
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        background: $c_pink;
        @include responsive((
          height: 6,
          top: 5,
          border-radius: 20,
        ));
        @include mq(sp) {
          height: mycalcSP(6);
          top: mycalc(5);
          border-radius: mycalcSP(20);
        }
      }
    }
  }

  .swiper-button-disabled {
    opacity: 0;
  }
}
