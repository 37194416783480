/*
 * contents.scss
 *
 * 記事詳細用コンポーネント
 */

.contents {

  @extend .container;
  @include responsive((
    margin-bottom: 320,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(192);
  }

  h1 {
    font-weight: 700;
    font-family: $f_point;
    border-bottom: dashed $c_pink;
    position: relative;
    @include responsive((
      font-size: 64,
      line-height: 112,
      padding-bottom: 16,
      margin-bottom: 96,
      border-bottom-width: 4,
    ));
    @include mq(sp) {
      letter-spacing: 0;
      font-size: mycalcSP(40);
      line-height: mycalcSP(72);
      padding-bottom: mycalcSP(16);
      margin-bottom: mycalcSP(64);
      border-bottom-width: mycalcSP(4);
    }
  }

  h2 {
    @extend .ttl_beta;
  }

  h3 {
    @extend .ttl_underline;
  }

  h4 {
    @extend .ttl_bar;
  }

  h5 {
    @extend .ttl_hyphen;
  }

  h6 {
    @extend .ttl_bold;
    color: $c_pink;
  }

  p, li, th, td, blockquote, q {

    @include responsive((
      font-size: 32,
      line-height: 72,
    ));

    @include mq(sp) {
      font-size: mycalcSP(30);
      line-height: mycalcSP(64);
    }

    a {
      color: $c_pink;
      border-bottom: 1px dashed $c_pink;
      display: inline;

      @include responsive((
        padding-bottom: 4,
      ));
      @include mq(sp) {
        padding-bottom: mycalcSP(4);
      }

      &:hover {
        border-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p, ul, ol, table, blockquote, q, .table_wrap {
    &:not(:last-child) {
      @include responsive((
        margin-bottom: 64,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(64);
      }
    }
  }

  li {
    position: relative;
    @include responsive((
      padding-left: 32,
      line-height: 60,
    ));
    @include mq(sp) {
      padding-left: mycalcSP(30);
      line-height: mycalcSP(48);
    }

    &:not(:last-child) {
      @include responsive((
        margin-bottom: 24,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(20);
      }
    }
  }

  ul {
    > li:before {
      content: "";
      background: $c_pink;
      border-radius: 50%;
      position: absolute;
      left: 0;
      @include responsive((
        width: 16,
        height: 16,
        top: 20,
      ));
      @include mq(sp) {
        width: mycalcSP(16);
        height: mycalcSP(16);
        top: mycalcSP(20);
      }
    }
  }

  ol {
    counter-reset: number;

    > li {
      counter-increment: number;
      @include responsive((
        padding-left: 56,
      ));
      @include mq(sp) {
        padding-left: mycalcSP(56);
      }

      &:before {
        content: counter(number, decimal-leading-zero) ".";
        font-family: $f_en;
        position: absolute;
        left: 0;
        letter-spacing: 0;
        font-weight: 700;
        color: $c_pink;
        @include responsive((
          top: 0,
        ));
        @include mq(sp) {
          top: mycalcSP(0);
        }
      }
    }
  }

  ul, ol {
    ul, ol {
      @include responsive((
        margin-top: 32,
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-top: mycalcSP(32);
        margin-bottom: mycalcSP(32);
      }
    }
  }

  table {
    width: auto;

    tr {}

    th, td {
      @include responsive((
        padding: 24 32,
      ));
      @include mq(sp) {
        padding: mycalcSP(24 32);
      }
    }

    th {
      font-weight: bold;
      border: 1px solid $c_white;
      background: $c_gray;
    }

    td {
      border: 1px solid $c_white;
      background: $c_gray_light;
      @include responsive((
        font-size: 28,
        line-height: 48,
      ));

      @include mq(sp) {
        font-size: mycalcSP(28);
        line-height: mycalcSP(48);
      }
    }
  }

  .table_wrap {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    background: $c_white;
    box-sizing: border-box;
    @include responsive((
      padding: 64 80,
      border-radius: 64,
    ));

    @include mq(sp) {
      padding: mycalcSP(64 80);
      border-radius: mycalcSP(64);
    }
  }

  strong {
    font-weight: bold;
  }

  blockquote, q {
    position: relative;
    display: inline-block;
    background: $c_white;
    @include responsive((
      padding: 64 128,
      border-radius: 64,
    ));
    @include mq(sp) {
      padding: mycalcSP(64 128);
      border-radius: mycalcSP(64);
    }

    &:before,
    &:after {
      color: $c_pink;
      position: absolute;
      line-height: 1;
      font-family: $f_point;
      @include responsive((
        font-size: 128,
      ));
      @include mq(sp) {
        font-size: mycalcSP(128);
      }
    }

    &:before {
      content: "“";
      @include responsive((
        top: 20,
        left: 40,
      ));
      @include mq(sp) {
        top: mycalcSP(20);
        left: mycalcSP(40);
      }
    }

    &:after {
      content: "”";
      @include responsive((
        right: 40,
        bottom: -40,
      ));
      @include mq(sp) {
        right: mycalcSP(40);
        bottom: mycalcSP(-40);
      }
    }
  }

  &_ttl {
    position: relative;
    @include responsive((
      padding-bottom: 48,
      margin-bottom: 48,
    ));
    @include mq(sp) {
      padding-bottom: mycalcSP(48);
      margin-bottom: mycalcSP(48);
    }

    p {
      text-align: center;
      @include responsive((
        font-size: 36,
        line-height: 80,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(72);
      }
    }
  }
}

.inner_contents {
  p, li {

    @include responsive((
      font-size: 28,
      line-height: 56,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(56);
    }
  }

  p, ul, ol {
    &:not(:last-child) {
      @include responsive((
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(32);
      }
    }
  }

  li {
    position: relative;
    @include responsive((
      padding-left: 32,
    ));
    @include mq(sp) {
      padding-left: mycalcSP(32);
    }

    &:before {
      content: "";
      background: $c_pink;
      border-radius: 50%;
      position: absolute;
      left: 0;
      @include responsive((
        width: 16,
        height: 16,
        top: 16,
      ));
      @include mq(sp) {
        width: mycalcSP(16);
        height: mycalcSP(16);
        top: mycalcSP(16);
      }
    }

    &:not(:last-child) {
      @include responsive((
        margin-bottom: 16,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(16);
      }
    }
  }
}

.post_meta {
  @include responsive((
    margin-bottom: 96,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(96);
  }

  &_date {
    color: $c_text;
    font-family: $f_en;
    @include responsive((
      margin-right: 24
    ));
    @include mq(sp) {
      margin-right: mycalcSP(24);
    }
  }

  &_cat {
    @extend .tag;
  }
}

// WordPressのエディターにも適応する場合、以下を適応。
body.wp-editor {
  box-sizing: border-box;
  padding: 20px !important;
  @extend .contents;
}