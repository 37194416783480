.sub {
  &_sec {
    position: relative;
    @include responsive((
      margin-bottom: 240,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(192);
    }

    &_ttl {
      width: 100%;
      box-sizing: border-box;
      font-weight: bold;
      letter-spacing: 0;
      font-family: $f_point;
      position: relative;
      @include responsive((
        font-size: 64,
        line-height: 100,
        margin-bottom: 64,
        padding: 0 32,
      ));
      @include mq(sp) {
        font-size: mycalcSP(48);
        line-height: mycalcSP(72);
        margin-bottom: mycalcSP(64);
        padding: mycalcSP(0 24);
      }

      .num, .txt {
        font-weight: inherit;
        letter-spacing: inherit;
      }

      .num {
        position: absolute;
        top: 0;
        @include responsive((
          left: 32,
        ));
        @include mq(sp) {
          left: mycalcSP(24);
        }
      }

      .txt {
        display: block;
        @include responsive((
          padding-left: 108,
        ));
        @include mq(sp) {
          padding-left: mycalcSP(68);
        }
      }
    }

    &_desc {
      width: 100%;
      box-sizing: border-box;
      @include responsive((
        font-size: 32,
        line-height: 80,
        padding: 0 32,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(72);
        padding: mycalcSP(0 24);
        letter-spacing: 0;
      }
    }

    &_list {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      @include responsive((
        margin-top: 96,
        padding: 0 32,
      ));
      @include mq(sp) {
        margin-top: mycalcSP(96);
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
      }

      &_item {
        @include responsive((
          width: 1112,
          margin: 0 40,
        ));
        @include mq(sp) {
          width: mycalcSP(670);
          margin: mycalcSP(0 auto 64);
        }

        &:nth-child(odd) {
          margin-left: 0;
          @include mq(sp) {
            margin-top: 0;
          }
        }

        &:nth-child(even) {
          margin-right: 0;
          @include mq(sp) {
            margin-top: 0;
          }
        }

        &:last-child {
          @include mq(sp) {
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        &_figure {
          position: relative;
          overflow: hidden;
          box-sizing: border-box;
          @include responsive((
            width: 1112,
            height: 624,
            border-radius: 96,
            padding: 16,
          ));
          @include mq(sp) {
            width: mycalcSP(670);
            height: mycalcSP(444);
            border-radius: mycalcSP(96);
            padding: mycalcSP(16);
          }

          &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            border: $c_white solid;
            @include responsive((
              border-radius: 96,
              border-width: 32,
            ));
            @include mq(sp) {
              border-radius: mycalcSP(96);
              border-width: mycalcSP(32);
            }
          }

          &_img {
            @include responsive((
              width: 1080,
              height: 592,
              border-radius: 96,
            ));
            @include mq(sp) {
              width: mycalcSP(638);
              height: mycalcSP(412);
              border-radius: mycalcSP(96);
            }
          }
        }

        .ico_logo_studio,
        .ico_logo_academy {
          position: absolute;
          top: 50%;
          left: 50%;
        }

        .ico_logo_studio {
          @include responsive((
            width: 388,
            height: 128,
            margin-top: -64,
            margin-left: -194,
          ));
          @include mq(sp) {
            width: mycalcSP(388);
            height: mycalcSP(128);
            margin-top: mycalcSP(-64);
            margin-left: mycalcSP(-194);
          }
        }

        .ico_logo_academy {
          @include responsive((
            width: 454,
            height: 128,
            margin-top: -64,
            margin-left: -227,
          ));
          @include mq(sp) {
            width: mycalcSP(454);
            height: mycalcSP(128);
            margin-top: mycalcSP(-64);
            margin-left: mycalcSP(-227);
          }
        }
      }
    }

    .btn {
      margin: 0 0 0 auto;
      transform: translate(16px, -40px);
      @include mq(pc) {
        transform: translate(#{mycalc(32)}, #{mycalc(-80)});
      }
      @include mq(sp) {
        transform: none;
        width: mycalcSP(560);
        height: mycalcSP(112);
        margin: mycalcSP(-64 auto 0);
      }
    }
  }
}

.business {
  @include responsive((
    margin: 240 auto,
  ));
  @include mq(sp) {
    margin: mycalcSP(192 auto);
  }

  .course_list_item_bnr_data {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.outline {
  position: relative;
  z-index: 0;
  @include responsive((
    margin-bottom: 240,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(192);
  }

  &_table {
    width: 100%;
    background: $c_white;
    box-sizing: border-box;
    @include responsive((
      margin-top: 64,
      border-radius: 64,
      padding: 64,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(64);
      border-radius: mycalcSP(64);
      padding: mycalcSP(64);
    }

    dl {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      border-bottom: 1px solid $c_border;

      @include mq(sp) {
        flex-wrap: wrap;
        flex-flow: column;
        align-items: flex-start;
        margin-bottom: mycalcSP(48);
      }

      &:last-child {
        border-bottom: 0;

        @include mq(sp) {
          margin-bottom: 0;
        }
      }
    }

    dt {
      box-sizing: border-box;
      font-weight: bold;
      @include responsive((
        width: 400,
        padding: 64,
        font-size: 32,
        line-height: 72,
      ));
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(30);
        line-height: mycalcSP(64);
        padding: mycalcSP(0);
        margin-bottom: mycalcSP(24);
      }
    }

    dd {
      box-sizing: border-box;
      @include responsive((
        width: 1872,
        padding: 64,
        font-size: 30,
        line-height: 68,
      ));
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(0);
        font-size: mycalcSP(28);
        line-height: mycalcSP(60);
        padding-bottom: mycalcSP(48);
      }
    }
  }
}


.access {
  position: relative;
  z-index: 0;
  width: 100%;
  @include responsive((
    margin-bottom: 320,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(192);
  }
}

.map {
  position: relative;
  margin: 0 auto;
  background: $c_white;
  overflow: hidden;
  @include responsive((
    width: 2400,
    height: 800,
    margin-top: 92,
    margin-bottom: 130,
    border-radius: 64,
  ));
  @include mq(sp) {
    width: mycalcSP(672);
    height: auto;
    margin-top: mycalcSP(80);
    margin-bottom: mycalcSP(152);
    border-radius: mycalcSP(64);
  }

  &_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include mq(sp) {
      height: mycalcSP(516);
    }
  }

  iframe {
    position: absolute;
    width: 200%;
    height: 200%;
    left: -50%;
    top: -50%;
  }
}

.iframe_wrap {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background: $c_white;
  @include responsive((
    width: 1112,
    height: 624,
    border-radius: 96,
    padding: 32,
    margin-top: 96,
  ));
  @include mq(sp) {
    width: mycalcSP(670);
    height: mycalcSP(444);
    border-radius: mycalcSP(96);
    padding: mycalcSP(32);
    margin-top: mycalcSP(96);
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border: $c_white solid;
    z-index: -1;
    @include responsive((
      border-radius: 64,
      border-width: 32,
    ));
    @include mq(sp) {
      border-radius: mycalcSP(64);
      border-width: mycalcSP(32);
    }
  }

  iframe {
    @include responsive((
      width: 1048,
      height: 560,
      border-radius: 96,
    ));
    @include mq(sp) {
      width: mycalcSP(606);
      height: mycalcSP(380);
      border-radius: mycalcSP(64);
    }
  }

  &_flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &_box {
      &:first-child {
        @include responsive((
          margin-right: 80,
        ));
      }
    }

    .iframe_wrap {
      margin-top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      height: auto;

      .video {
        @include responsive((
          border-radius: 64,
        ));
      }
    }

    .video_info_ja,
    .video_info_en {
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      @include responsive((
        font-size: 28,
        line-height: 56,
        margin-top: 32,
      ));
    }

    .video_info {
      width: 100%;
      text-align: center;
      @include responsive((
        font-size: 32,
        line-height: 56,
        margin-top: 32,
      ));

      @include mq(sp) {
        text-align: left;
      }
    }
  }
}