/*
 * pagenation.scss
 *
 * ページャー／ページネーション用コンポーネント
 */


// 詳細ページのページャー想定
.pager {
  width: 100%;

  &_list {
    width: 100%;
    @include responsive($list: (
      margin: 84 auto,
    ));
    @include flex(row, center, center, none, wrap);
    @include mq(sp) {
      margin: mycalcSP(64 auto);
    }

    &_num {
      @include responsive($list: (
        width: 88,
        height: 88,
        margin: 20,
      ));

      @include mq(sp) {
        width: mycalcSP(80);
        height: mycalcSP(80);
        margin: mycalcSP(8);
      }

      span, a {
        @include responsive($list: (
          width: 88,
          height: 88
        ));
        @include flex(row, center, center, none, wrap);
        box-sizing: border-box;
        background: $c_pink;
        color: $c_white;
        border: 2px solid $c_pink;
        transition: background $p_hum ease, color $p_hum ease;
        font-size: 1.4rem;
        font-family: $f_en;
        font-weight: bold;
        @include mq(sp) {
          width: mycalcSP(80);
          height: mycalcSP(80);
          font-size: mycalcSP(24);
        }
      }

      .current,
      a:hover {
        background: $c_pink_light;
        color: $c_pink;
      }

      &:first-child{
        margin-left: 0;
      }

      &:last-child{
        margin-right: 0;
      }

      &.prev,
      &.next {
        a {
          position: relative;

          &:after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            box-sizing: border-box;
            border-right-color: $c_white;
            border-top-color: $c_white;
            border-right-style: solid;
            border-top-style: solid;
            transform: rotate(-45deg);
            transition: border-color $t_hv;
            transition-delay: .1s;
            border-right-width : 2px;
            border-top-width : 2px;

            @include responsive($list: (
              width : 20,
              height : 20,
              margin-left : -10,
              margin-top : -9,
            ));

            @include mq(sp) {
              width: mycalcSP(20);
              height: mycalcSP(20);
              margin-left: mycalcSP(-10);
              margin-top: mycalcSP(-8);
            }
          }

          &:hover {
            &:after {
              transition-delay: 0s;
              border-right-color: $c_pink;
              border-top-color: $c_pink;
            }
          }
        }
      }

      &.prev a:after{
        transform: rotate(-135deg);
        @include responsive($list: (
          margin-left : -6,
        ));
        @include mq(sp) {
          margin-left: mycalcSP(-6);
        }
      }

      &.next a:after{
        transform: rotate(45deg);
        @include responsive($list: (
          margin-left : -14,
        ));
        @include mq(sp) {
          margin-left: mycalcSP(-14);
        }
      }
    }
  }
}


// 一覧ページのページャー想定
.pagenation {
  width: 100%;
  border-top: 1px solid $c_border;
  margin: 0 auto;
  @include flex(row, center, center, none, wrap);
  @include responsive($list: (
    max-width : 2400,
    padding-top: 136,
    margin-top: 112,
    margin-bottom: 200,
  ));
  @include mq(sp) {
    width: mycalcSP(638);
    padding-top: mycalcSP(80);
    margin-top: mycalcSP(50);
    margin-bottom: mycalcSP(50);
  }

  .btn {
    @include responsive($list: (
      margin: 0 56,
    ));
    @include mq(sp) {
      padding: mycalcSP(0 60);
      margin: mycalcSP(0 0 48);
      order: -1;
    }

    &_txt{
      font-size: 1.4rem;
    }
  }

  .pagenation_list {
    @include responsive($list: (
      width : 88,
      height : 88,
    ));
    @include mq(sp) {
      width: mycalcSP(88);
      height: mycalcSP(88);
      margin: mycalcSP(0 12);
    }

    a{
      position: relative;
      box-sizing: border-box;
      border: 2px solid $c_pink;
      background: $c_white;
      color: $c_pink;
      transition: $t_hv;
      border-radius: 50%;
      overflow: hidden;
      @include flex(row, center, center, none, nowrap);
      @include responsive($list: (
        width : 88,
        height : 88,
      ));
      @include mq(sp) {
        width: mycalcSP(88);
        height: mycalcSP(88);
      }

      &:before{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        box-sizing: border-box;
        border-right-color: $c_pink;
        border-top-color: $c_pink;
        border-right-style: solid;
        border-top-style: solid;
        transform: rotate(-45deg);
        transition: $t_hv;
        z-index: 1;

        @include responsive($list: (
          width : 20,
          height : 20,
          margin-left : -10,
          margin-top : -9,
          border-right-width : 4,
          border-top-width : 4
        ));

        @include mq(sp) {
          width: mycalcSP(20);
          height: mycalcSP(20);
          margin-left: mycalcSP(-10);
          margin-top: mycalcSP(-8);
          border-right-width: mycalcSP(4);
          border-top-width: mycalcSP(4);
        }
      }

      &:after {
        content: "";
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        background: $c_pink;
        transition: width $t_slide, left $t_slide;
        z-index: 0;
        //border-radius: 50%;
      }

      &:hover {

        &:before {
          border-right-color: $c_white;
          border-top-color: $c_white;
        }

        &:after {
          width: 100%;
        }
      }

      &[rel="prev"] {
        &:after {
          left: 0;
        }
        &:hover:after {
          right: 0;
          left: auto;
        }
      }

      &[rel="next"] {
        &:after {
          right: 0;
        }
        &:hover:after {
          left: 0;
          right: auto;
        }
      }
    }

    &.prev a:before{
      transform: rotate(-135deg);
      @include responsive($list: (
        margin-left : -6,
      ));
      @include mq(sp) {
        margin-left: mycalcSP(-6);
      }
    }

    &.next a:before{
      transform: rotate(45deg);
      @include responsive($list: (
        margin-left : -14,
      ));
      @include mq(sp) {
        margin-left: mycalcSP(-14);
      }
    }
  }
}
