/*
 * btn.scss
 *
 * ボタン用コンポーネント
 * 定義する際は、接頭辞としてbtn_を付けること。
 */


.btn {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  background: $c_pink;
  border: 1px solid $c_pink;
  transition: color $t_slide;
  overflow: hidden;
  @include responsive((
    width: 640,
    height: 160,
    border-width: 4,
    border-radius: 80,
  ));
  @include mq(sp) {
    width: mycalcSP(640);
    height: mycalcSP(128);
    border-width: mycalcSP(4);
    border-radius: mycalcSP(80);
  }

  &_link {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    color: $c_white;
    letter-spacing: 0;
    @include responsive((
      border-radius: 80,
    ));
    @include mq(sp) {
      border-radius: mycalcSP(80);
    }
    @include textGradient($c_white, $c_pink, '.btn_link_txt', $p_slide, .1s);

    &_txt {
      position: relative;
      z-index: 1;
      font-weight: bold;
      @include responsive((
        font-size: 32,
        line-height: 64,
      ));
      @include mq(sp) {
        font-size: mycalcSP(28);
        line-height: mycalcSP(48);
      }
    }

    svg {
      z-index: 1;
    }

    .ico_arw {
      position: absolute;
      @include responsive((
        width: 32,
        height: 32,
        right: 48,
      ));
      @include mq(sp) {
        width: mycalcSP(32);
        height: mycalcSP(32);
        right: mycalcSP(40);
      }

      path {
        fill: $c_white;
        transition: fill $t_slide .2s;
      }
    }

    .ico_mail {
      @include responsive((
        width: 48,
        height: 48,
        margin-right: 8,
      ));
      @include mq(sp) {
        width: mycalcSP(40);
        height: mycalcSP(40);
        margin-right: mycalcSP(8);
      }
      path {
        fill: $c_white;
        transition: fill $t_slide .2s;
        transition-delay: 0s;
      }
    }

    &:after {
      content: "";
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: $c_white;
      transition: width $t_slide, left $t_slide;
      z-index: 0;
      @include responsive((
        border-radius: 80,
      ));
      @include mq(sp) {
        border-radius: mycalcSP(80);
      }
    }

    &:hover {
      color: $c_text;

      svg path {
        fill: $c_pink;
      }

      &:after {
        width: 100%;
        left: 0;
      }
    }
  }

  .studio & {
    background: $c_studio;
    border-color: $c_studio;

    &_link {
      @include textGradient($c_white, $c_studio, '.btn_link_txt', $p_slide, .1s);

      &:hover svg path {
        fill: $c_studio;
      }
    }
  }

  .academy & {
    background: $c_academy;
    border-color: $c_academy;

    &_link {
      @include textGradient($c_white, $c_academy, '.btn_link_txt', $p_slide, .1s);

      &:hover svg path {
        fill: $c_academy;
      }
    }
  }

  &.white {
    background: $c_white;
    border-color: $c_white;

    &:after {
      background: $c_pink_light;
    }
  }

  &.white &_link {
    @include textGradient($c_text, $c_text, '.btn_link_txt', $p_slide, .1s);
    color: $c_text;

    .ico_arw path{
      fill: $c_pink;
    }
  }
}


.btn_back {
  @extend .btn;

  &:after {
    right: auto;
    left: 0;
  }

  &_link {
    @extend .btn_link;
    @include textGradient($c_white, $c_pink, '.btn_back_link_txt', $p_slide, .1s);

    .btn_back_link_txt {
      @extend .btn_link_txt;
      animation: TEXT-MASK-OVER-BACK $p_slide cubic-bezier(.23, 1, .32, 1) 0s forwards;
    }

    svg {
      z-index: 1;
    }

    .ico_arw {
      position: absolute;
      transform: rotate(180deg);
      @include responsive((
        width: 32,
        height: 32,
        left: 48,
      ));
      @include mq(sp) {
        width: mycalcSP(32);
        height: mycalcSP(32);
        left: mycalcSP(40);
      }

      path {
        fill: $c_white;
        transition: fill $t_slide .2s;
      }
    }

    &:after {
      content: "";
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $c_white;
      transition: width $t_slide, left $t_slide;
      z-index: 0;
      @include responsive((
        border-radius: 80,
      ));
      @include mq(sp) {
        border-radius: mycalcSP(80);
      }
    }

    &:hover {
      &:after {
        left: auto;
        right: 0;
      }
      &:hover .btn_back_link_txt {
        animation: TEXT-MASK-BACK $p_slide cubic-bezier(0.23, 1, 0.32, 1) .1s forwards;
      }
    }
  }
}


.btn_circle {
  position: relative;
  box-sizing: border-box;
  background: $c_pink;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: transform $t_scroll;
  @include responsive((
    width: 288,
    height: 288,
    margin-left: 80,
    margin-right: 64,
  ));
  @media screen and (max-width: 1400px) {
    width: mycalc(260);
    height: mycalc(260);
  }
  @include mq(pc) {
    margin-right: mycalc(44);
  }
  @include mq(sp) {
    width: mycalcSP(640);
    height: mycalcSP(112);
    margin: 0 auto;
    border-radius: mycalcSP(64);
  }

  &_link {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: $c_white;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0;
    font-weight: bold;
    font-family: $f_point;
    position: relative;
    box-sizing: border-box;
    @include responsive((
      font-size: 36,
      line-height: 48,
    ));
    @media screen and (max-width: 1400px) {
      font-size: mycalc(30);
      line-height: mycalc(40);
    }
    @include mq(sp) {
      position: relative;
      font-size: mycalcSP(32);
      line-height: mycalcSP(48);
    }

    &_txt {
      letter-spacing: inherit;
      font-weight: inherit;
      position: relative;
      z-index: 1;
    }

    .ico_arw {
      display: none;

      @include mq(sp) {
        display: block;
        width: mycalcSP(32);
        height: mycalcSP(32);
        position: absolute;
        right: mycalcSP(40);

        path {
          fill: $c_white;
        }
      }
    }

    .circle,
    .circle_outer {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      transition: transform .6s $e_scroll;
      transform: scale(.8);

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-sizing: border-box;
        border: dashed transparent;
        transition: border-color .6s $e_scroll;
        @include responsive((
          border-width: 4,
        ));
      }
    }

    &:hover {
      .circle {
        transform: scale(1.1);

        &:after {
          border-color: $c_pink;
          animation: rotation 8s infinite linear;
        }
      }

      .circle_outer {
        transform: scale(1.1);

        &:after {
          border-color: $c_white;
          animation: rotation 16s infinite linear reverse;
        }
      }
    }


    /*
    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      margin-top: -50%;
      margin-left: -50%;
      transform: scale(0);
      transition: transform $t_bubble;
      z-index: 0;
    }

    &:before {
      background: $c_text;
      transition-delay: .4s;
    }

    &:after {
      transform: scale(1);
      background: $c_pink;
    }

    &:hover {
      &:before {
        transform: scale(1);
        transition: transform $t_bubble;
      }
      &:after {
        z-index: -1;
        transform: scale(0);
      }
    }
    */
  }
}


.tel {
  @include responsive((
    margin-right: 32,
  ));
  @include mq(sp) {
    margin-right: 0;
    margin-bottom: mycalcSP(32);
  }

  &_number {
    @include mq(sp) {
      box-sizing: border-box;
      display: flex;
      align-items: stretch;
      justify-content: center;
      position: relative;
      background: $c_white;
      border: mycalcSP(4) solid $c_pink;
      transition: color $t_slide;
      overflow: hidden;
      width: 100%;
      height: mycalcSP(112);
      border-radius: mycalcSP(80);
      margin-bottom: mycalcSP(8);

      .studio & {
        border-color: $c_studio;
      }
      .academy & {
        border-color: $c_academy;
      }
    }

    &_ico {
      @include responsive((
        margin-right: 16,
      ));
      @include mq(sp) {
        margin-right: mycalcSP(8);
      }

      svg {
        display: block;
        @include responsive((
          width: 48,
          height: 48,
        ));
        @include mq(sp) {
          width: mycalcSP(40);
          height: mycalcSP(40);
        }
      }
    }

    &_link {
      display: flex;
      justify-content: center;
      align-items: baseline;

      @include mq(sp) {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        align-items: center;
        position: relative;
        z-index: 1;
        color: $c_pink;
        letter-spacing: 0;
        border-radius: mycalcSP(80);

        &:after {
          background: $c_pink;

          .studio & {
            background: $c_studio;
          }

          .academy & {
            background: $c_academy;
          }
        }
      }
    }

    &_txt {
      letter-spacing: 0;
      font-weight: bold;
      color: $c_pink;
      @include responsive((
        font-size: 64,
        line-height: 64,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(48);
      }
    }
  }

  &_schedule {
    letter-spacing: 0;
    font-weight: bold;
    text-align: center;
    @include responsive((
      font-size: 20,
      line-height: 40,
    ));
    @include mq(sp) {
      font-size: mycalcSP(20);
      line-height: mycalcSP(40);

      .tb_invisible {
        display: inline;
      }
    }
  }
}


.mail {
  background: $c_white;
  @include responsive((
    width: 400,
    height: 112,
  ));
  @include mq(sp) {
    width: mycalcSP(640);
    height: mycalcSP(112);
  }

  &_link {
    @include textGradient($c_pink, $c_white, '.btn_link_txt', $p_slide, .1s);
    color: $c_pink;

    .ico_mail {
      @include responsive((
        width: 48,
        height: 48,
        margin-right: 8,
      ));
      @include mq(sp) {
        width: mycalcSP(40);
        height: mycalcSP(40);
        margin-right: mycalcSP(8);
      }

      path{
        fill: $c_pink;
        transition: fill $t_slide .01s;
      }
    }

    &:hover .ico_mail path {
      fill: $c_white;
    }

    &_txt {
      @include responsive((
        font-size: 32,
        line-height: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(48);
      }

      &_way {
        font-weight: inherit;
      }
    }


    &:after {
      background: $c_pink;

      .studio & {
        background: $c_studio;
      }

      .academy & {
        background: $c_academy;
      }
    }
  }
}
