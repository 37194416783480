/*
 * archive.scss
 *
 * 記事一覧用コンポーネント
 */


.archive {
  @include responsive((
    margin-bottom: 240,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(192);
  }

  &_list {
    &_item {
      border-bottom: 1px solid $c_border;
      @include responsive((
        padding: 56 0,
      ));
      @include mq(sp) {
        padding: mycalcSP(56 0);
      }

      .time {
        flex-shrink: 0;

        font-family: $f_en;
        @include responsive((
          font-size: 26,
          line-height: 40,
          margin-right: 44,
        ));
        @include mq(sp) {
          font-size: mycalcSP(26);
          line-height: mycalcSP(40);
          margin-right: mycalcSP(20);
        }
      }

      .tag {
        @include responsive((
          margin-right: 64,
        ));
        @include mq(sp) {
          margin-right: mycalcSP(32);
        }
      }

      .title {

        @include responsive((
          font-size: 28,
          line-height: 40,
        ));
        @include mq(sp) {
          width: 100%;
          font-size: mycalcSP(28);
          line-height: mycalcSP(40);
          margin-top: mycalcSP(32);
          display: block;
        }
      }

      &_link:hover {
        .title {
          text-decoration: underline;
        }
      }
    }
  }

  &_card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}